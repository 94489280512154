var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":[
      {
        text: 'Name',
        align: 'start',
        value: 'name',
        width: '25%'
      },
      {
        text: 'Beschreibung',
        value: 'description',
        width: '33%'
      },
      {
        text: _vm.isOneTimeProduct === true ? 'Einmaliger Preis' : 'Preis',
        value: 'price',
        width: '12%'
      },
      { text: 'Gültig ab', value: 'validFrom', width: '12%' },
      { text: 'Gültig bis', value: 'validUntil', width: '15%' }
    ],"items":_vm.availableProducts,"single-select":_vm.singleSelect,"item-key":"id","disabled":_vm.disabled,"dense":"","options":{ itemsPerPage: -1 },"hide-default-footer":"","show-select":""},on:{"input":function($event){return _vm.emitCurrentState()}},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(ref){return undefined}},{key:"item.data-table-select",fn:function(ref){
    var item = ref.item;
    var isSelected = ref.isSelected;
    var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"value":isSelected,"disabled":_vm.disabled},on:{"input":function($event){return select($event)}}})]}},{key:"item.description",fn:function(ref){
    var item = ref.item;
return [(item.description)?_c('div',[_vm._v(" "+_vm._s(item.description)+" ")]):_c('div',[_vm._v(" - ")])]}},{key:"item.price",fn:function(ref){
    var item = ref.item;
return [(item.price)?_c('div',[_vm._v(" "+_vm._s(item.price)+" € ")]):_c('div',[_vm._v(" - ")])]}},{key:"item.validFrom",fn:function(ref){
    var item = ref.item;
return [(item.validFrom)?_c('div',[_vm._v(" "+_vm._s(_vm.formatLocalDate(item.validFrom))+" ")]):_c('div',[_vm._v(" - ")])]}},{key:"item.validUntil",fn:function(ref){
    var item = ref.item;
return [(item.validUntil)?_c('div',[_vm._v(" "+_vm._s(_vm.formatLocalDate(item.validUntil))+" ")]):_c('div',[_vm._v(" - ")])]}}]),model:{value:(_vm.selection),callback:function ($$v) {_vm.selection=$$v},expression:"selection"}},[_c('template',{slot:"no-data"},[_vm._v(" Es existieren keine Produkte von diesem Dokumententyp. ")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }