var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.hasErrors(_vm.collectedValidationErrors) && _vm.displayError)?_c('ValidationErrorDisplay',{attrs:{"validatitationErrors":_vm.collectedValidationErrors}}):_vm._e(),(
      (_vm.hasErrors(_vm.validationErrorsFromBackend) ||
        _vm.hasErrors(_vm.validationErrorsForAgency)) &&
        !_vm.displayError
    )?_c('ValidationErrorDisplay',{attrs:{"validatitationErrors":_vm.mergeValidationErrors(
        _vm.validationErrorsFromBackend,
        _vm.validationErrorsForAgency
      )}}):_vm._e(),_c('DataDisplay',{attrs:{"label":"Liegenschaftsstatus"}},[(_vm.propertyStatus)?_c('div',[_c('v-tooltip',{attrs:{"top":"","disabled":!_vm.propertyStatus.projectId},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [(
              _vm.propertyStatus.status === 'home-connected' ||
                _vm.propertyStatus.status === 'premises-connected' ||
                _vm.propertyStatus.status === 'home-passed'
            )?_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-home-assistant ")]):(
              _vm.propertyStatus.status === 'project-planned' ||
                _vm.propertyStatus.status === 'project-started'
            )?_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-home-planed ")]):_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-home-remove ")])]}}],null,false,1355230728)},[_c('span',[_vm._v(" "+_vm._s(_vm.propertyStatus.projectId)+" ")])]),_vm._v(" "+_vm._s(_vm.propertyStatus.status)+" ")],1):_vm._e()]),_c('v-tabs',{staticClass:"v-tabs margin-top-1",attrs:{"background-color":'var(--goe-background-highlight)'},model:{value:(_vm.selectedTabIndex),callback:function ($$v) {_vm.selectedTabIndex=$$v},expression:"selectedTabIndex"}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_vm._l((_vm.tabOptions),function(item){return _c('v-tab',{key:item.goetelShipsDevices,attrs:{"disabled":_vm.disabled || _vm.backendCallActive || item.disabled}},[_vm._v(" "+_vm._s(item.title)+" ")])})],2),_c('v-tabs-items',{staticClass:"v-tabs-items-color margin-top-1",staticStyle:{"background-color":"var(--goe-background-highlight)"},attrs:{"background-color":'var(--goe-background-highlight)'},model:{value:(_vm.selectedTabIndex),callback:function ($$v) {_vm.selectedTabIndex=$$v},expression:"selectedTabIndex"}},[_c('v-tab-item',{staticClass:"margin-top-1",attrs:{"eager":""}},[_c('AddressAutocomplete',{attrs:{"previouslySelectedAddress":_vm.installationAddress,"disabled":_vm.disabled,"isInstallationAddress":true},on:{"update":function($event){{
            _vm.updateCurrentState($event);
            _vm.validationErrorsAddressWithHouseNumber = $event.validationErrors;
            if (
              _vm.tabOptions[_vm.selectedTabIndex]
                .alternativeLocationDescriptionIsRequired === false
            ) {
              _vm.validateInstallationAddressWithHouseNumber();
              _vm.updatePropertyStatus();
            }
          }}}})],1),_c('v-tab-item',{staticClass:"margin-top-1",attrs:{"eager":""}},[_c('AddressAutocomplete',{attrs:{"previouslySelectedAddress":_vm.installationAddress,"disabled":_vm.disabled,"alternativeLocationDescriptionIsRequired":true,"isInstallationAddress":true},on:{"update":function($event){{
            _vm.updateCurrentState($event);
            _vm.validationErrorsAddressWithAlternativeDesciption =
              $event.validationErrors;
            if (
              _vm.tabOptions[_vm.selectedTabIndex]
                .alternativeLocationDescriptionIsRequired === true
            ) {
              _vm.validateInstallationAddressWithAlternativeDesciption();
              _vm.updatePropertyStatus();
            }
          }}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }