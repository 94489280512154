<template>
  <div>
    <ValidationErrorDisplay
      v-if="hasErrors(validationErrors) && displayError"
      :validatitationErrors="validationErrors"
    />
    <CustomerInformationSelection
      :disabled="disabled"
      :previouslySelectedCustomerInformation="
        addressInformation.customerInformation
      "
      @customerInformationChanged="
        {
          addressInformation.customerInformation = $event.value;
          validationErrors.customerInformation = $event.validationErrors;
          updateCurrentState();
        }
      "
    />
    <AddressAutocomplete
      :disabled="disabled"
      :previouslySelectedAddress="addressInformation.address"
      @update="
        {
          addressInformation.address = $event.value;
          validationErrors.address = $event.validationErrors;
          updateCurrentState();
        }
      "
    />
  </div>
</template>

<script>
import CustomerInformationSelection from './CustomerInformationSelection.vue';
import AddressAutocomplete from './AddressAutocomplete.vue';
import ValidationErrorDisplay from '../ValidationErrorDisplay.vue';

import lodash from 'lodash';

import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import { hasErrors } from '../ValidationError.js';

export default {
  name: 'OwnerSelection',
  components: {
    DisplayContainer,
    CustomerInformationSelection,
    AddressAutocomplete,
    ValidationErrorDisplay
  },
  props: {
    previouslySelectedAddressInformation: {
      type: Object,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    displayError: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    addressInformation: null,
    validationErrors: null
  }),
  created() {
    if (this.$props.previouslySelectedAddressInformation) {
      this.addressInformation = lodash.cloneDeep(
        this.$props.previouslySelectedAddressInformation
      );
    } else {
      this.addressInformation = this.buildDefaultAddressInformationSelection();
    }
    this.validationErrors = this.buildInitialValidationErrors();
    this.updateCurrentState();
  },
  methods: {
    updateCurrentState() {
      this.emitCurrentState();
    },
    emitCurrentState() {
      this.$emit('addressInformationChanged', {
        value: this.addressInformation,
        validationErrors: this.validationErrors
      });
    },
    hasErrors(validationErrors) {
      return hasErrors(validationErrors);
    },
    buildInitialValidationErrors() {
      return {
        _validationErrors: [],
        customerInformation: null,
        address: null
      };
    },
    buildDefaultAddressInformationSelection() {
      return {
        customerInformation: null,
        address: null
      };
    }
  }
};
</script>

<style scoped></style>
