import { render, staticRenderFns } from "./SelectPortingOptions.vue?vue&type=template&id=482b8123&scoped=true&"
import script from "./SelectPortingOptions.vue?vue&type=script&lang=js&"
export * from "./SelectPortingOptions.vue?vue&type=script&lang=js&"
import style0 from "./SelectPortingOptions.vue?vue&type=style&index=0&id=482b8123&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "482b8123",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAutocomplete,VIcon,VRadio,VRadioGroup,VTooltip})
