<template>
  <div>
    <DisplayContainer
      v-if="installationAddressCanBeInserted"
      label="Kontaktdaten"
      isSubcomponent
    >
      <div class="input-row">
        <NameDisplay :addressDto="customerData.customerInformation" />
        <div></div>
        <ContactInformationDisplay
          :addressDto="customerData.customerInformation"
        />
        <DateDisplay
          v-if="customerData.customerInformation.salutation != 'COMPANY'"
          label="Geburtsdatum"
          :date="customerData.customerInformation.birthDate"
        />
      </div>
    </DisplayContainer>

    <DisplayContainer label="Installationsadresse" isSubcomponent>
      <AddressDisplay :addressDto="customerData.installationAddress" />
    </DisplayContainer>
    <DisplayContainer
      v-if="ibanCanBeInserted"
      isSubcomponent
      label="Lastschriftmandat"
      :selectionConfiguration="{
        label: 'Zahlung per Lastschriftmandat',
        isSelected: customerData.sepaMandateIsSelected,
        isFoldable: true,
        disabled: true
      }"
    >
      <div class="margin-bottom-0_5">
        <SepaMandateDisplay
          v-if="ibanCanBeInserted"
          :sepaMandateDto="customerData.sepaMandate"
        />
      </div>
      <ValidationErrorDisplay
        v-if="hasErrors(validationErrorsCustomerData.sepaMandate) === true"
        :validatitationErrors="validationErrorsCustomerData.sepaMandate"
      />
    </DisplayContainer>

    <DisplayContainer
      v-if="invoiceAddressCanBeInserted"
      class="margin-top-0_5"
      label="Rechnungsadresse"
      isSubcomponent
      :selectionConfiguration="{
        label: 'Abweichend',
        isSelected: customerData.invoiceAddressIsSelected,
        isFoldable: true,
        disabled: true
      }"
    >
      <ContactInformation
        v-if="customerData.invoiceAddress"
        class="margin-bottom-0_5"
        :addressDto="
          buildAddressDto(
            customerData.invoiceAddress.customerInformation,
            customerData.invoiceAddress.address
          )
        "
      />
      <ValidationErrorDisplay
        v-if="hasErrors(validationErrorsCustomerData.invoiceAddress) === true"
        :validatitationErrors="validationErrorsCustomerData.invoiceAddress"
      />
    </DisplayContainer>

    <DisplayContainer
      v-if="ibanCanBeInserted"
      class="margin-top-0_5"
      label="Kontaktadresse"
      isSubcomponent
      :selectionConfiguration="{
        label: 'Abweichend',
        isSelected: customerData.contactAddressIsSelected,
        isFoldable: true,
        disabled: true
      }"
    >
      <ContactInformation
        v-if="customerData.contactAddress"
        class="margin-bottom-0_5"
        :addressDto="
          buildAddressDto(
            customerData.contactAddress.customerInformation,
            customerData.contactAddress.address
          )
        "
      />
      <ValidationErrorDisplay
        v-if="hasErrors(validationErrorsCustomerData.contactAddress) === true"
        :validatitationErrors="validationErrorsCustomerData.contactAddress"
      />
    </DisplayContainer>

    <DisplayContainer
      v-if="invoiceAddressCanBeInserted"
      class="margin-top-0_5"
      label="Versandadresse"
      isSubcomponent
      :selectionConfiguration="{
        label: 'Abweichend',
        isSelected: customerData.deviatingDeliveryAddressIsSelected,
        isFoldable: true,
        disabled: true
      }"
    >
      <ContactInformation
        v-if="customerData.deliveryAddress"
        class="margin-bottom-0_5"
        :addressDto="
          buildAddressDto(
            customerData.deliveryAddress.customerInformation,
            customerData.deliveryAddress.address
          )
        "
      />
      <ValidationErrorDisplay
        v-if="hasErrors(validationErrorsCustomerData.deliveryAddress) === true"
        :validatitationErrors="validationErrorsCustomerData.deliveryAddress"
      />
    </DisplayContainer>

    <DisplayContainer
      v-if="deviationOwnerInformationCanBeInserted"
      class="margin-top-0_5"
      label="Grundstückseigentümer"
      isSubcomponent
      :selectionConfiguration="{
        label: 'Abweichend',
        isSelected: customerData.deviatingOwnerInformationIsSelected,
        isFoldable: true,
        disabled: true
      }"
    >
      <div v-if="customerData.deviatingOwnerInformation" class="input-row">
        <DataDisplay label="Grundstückseigentümer zahlt Hausanschlussauftrag">
          {{
            customerData.deviatingOwnerInformation.ownerPaysForHouseConnection
              ? 'Ja'
              : 'Nein'
          }}
        </DataDisplay>
      </div>
      <ContactInformation
        v-if="customerData.deviatingOwnerInformation"
        class="margin-bottom-0_5"
        :addressDto="
          buildAddressDto(
            customerData.deviatingOwnerInformation.customerInformation,
            customerData.deviatingOwnerInformation.address
          )
        "
      />
      <ValidationErrorDisplay
        v-if="hasErrors(validationErrorsOwnerInformation) === true"
        :validatitationErrors="validationErrorsOwnerInformation"
      />
    </DisplayContainer>
  </div>
</template>

<script>
import lodash from 'lodash';

import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import NameDisplay from '@/components/dataDisplays/NameDisplay.vue';
import ContactInformation from '@/components/ContactInformation.vue';

import DataDisplay from '@/components/elements/DataDisplay.vue';
import DateDisplay from '@/components/dataDisplays/DateDisplay.vue';
import AddressDisplay from '@/components/dataDisplays/AddressDisplay.vue';
import ContactInformationDisplay from '@/components/dataDisplays/ContactInformationDisplay.vue';
import SepaMandateDisplay from '@/components/dataDisplays/SepaMandateDisplay.vue';
import ValidationErrorDisplay from '../ValidationErrorDisplay.vue';
import { buildAddressDto } from '../CustomerDataUtil.js';
import { hasErrors, mergeValidationErrors } from '../ValidationError.js';

export default {
  name: 'CustomerDataSummary',
  components: {
    DisplayContainer,
    NameDisplay,
    DateDisplay,
    AddressDisplay,
    ContactInformation,
    ContactInformationDisplay,
    SepaMandateDisplay,
    DataDisplay,
    ValidationErrorDisplay
  },
  props: {
    orderType: {
      // Enum indicating order type -> SERVICE || HOUSECONNECTION || HOUSECONNECTION_WITH_SERVICE
      type: String,
      required: true
    },
    previouslySelectedCustomerData: {
      type: Object,
      required: false,
      default: null
    },
    validationErrorsCustomerData: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    customerData: null,
    validationErrorsOwnerInformation: null
  }),
  computed: {
    dateFormatted() {
      return this.formatDate(this.birthday);
    },
    ibanCanBeInserted() {
      return this.orderType !== 'HOUSECONNECTION';
    },
    invoiceAddressCanBeInserted() {
      return this.orderType !== 'HOUSECONNECTION';
    },
    installationAddressCanBeInserted() {
      return true;
    },
    deviationOwnerInformationCanBeInserted() {
      return this.orderType !== 'SERVICE';
    }
  },
  created: function () {
    if (this.$props.previouslySelectedCustomerData) {
      this.customerData = lodash.cloneDeep(
        this.$props.previouslySelectedCustomerData
      );
    } else {
      this.customerData = {};
    }
    if (
      this.customerData.deviatingOwnerInformationIsSelected &&
      this.validationErrorsCustomerData.deviatingOwnerInformation
    ) {
      this.validationErrorsOwnerInformation = mergeValidationErrors(
        this.validationErrorsCustomerData.deviatingOwnerInformation
          .customerInformation,
        this.validationErrorsCustomerData.deviatingOwnerInformation.address
      );
    } else {
      this.validationErrorsOwnerInformation =
        this.validationErrorsCustomerData.deviatingOwnerInformation;
    }
  },
  methods: {
    hasErrors(validationErrors) {
      return hasErrors(validationErrors);
    },
    buildAddressDto(customerInformation, address) {
      return buildAddressDto(customerInformation, address);
    }
  }
};
</script>

<style scoped></style>
