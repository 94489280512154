<template>
  <div>
    <div class="v-radio-group margin-bottom-1">
      <v-radio-group
        :value="portingOptions.portPhoneNumbersWithCancellation"
        label="WBCI-Prozess-Typ"
        dense
        hide-details
        @change="
          portingOptions.portPhoneNumbersWithCancellation = $event;
          emitCurrentState();
        "
      >
        <v-radio
          :value="true"
          label="Rufnummerportierung mit Kündigung (KUE-MRN)"
        />
        <v-radio :value="false" label="Reine Rufnummernportierung (RRNP)" />
      </v-radio-group>
    </div>
    <div class="v-radio-group margin-bottom-1">
      <v-radio-group
        :value="portingOptions.portAllPhoneNumbers"
        label="Alle Rufnummern portieren?"
        dense
        hide-details
        @change="
          portingOptions.portAllPhoneNumbers = $event;
          emitCurrentState();
        "
      >
        <v-radio :value="true" label="Alle Rufnummern" />
        <v-radio :value="false" label="Nur ausgewählte Rufnummern" />
      </v-radio-group>
    </div>
    <div class="input-row margin-bottom-1">
      <NumberManagementService
        :invalid="!numberListValid"
        :numberList="portingOptions.phoneNumbers"
        label="Zu portierende Rufnummern *"
        style="width: 60%"
        @numberAdded="addNumber($event)"
        @numberRemoved="removeNumber($event)"
      />
    </div>
    <DisplayContainer :isSubcomponent="true" label="Bisheriger Anbieter">
      <div class="input-row">
        <v-autocomplete
          :error-messages="carrierErrorMessage"
          :item-text="
            (item) => {
              return `${item.name} — (${item.routingId})`;
            }
          "
          :items="fetchedCarrierList"
          :label="'Bisheriger Anbieter'"
          :loading="carrierListLoading"
          :value="portingOptions.dimariCarrierDto"
          clearable
          persistent-placeholder
          placeholder="Carrier auswählen..."
          return-object
          @input="
            {
              portingOptions.dimariCarrierDto = $event;
              emitCurrentState();
            }
          "
        />
        <div>
          <div class="label">
            Vertragspartner
          </div>
          <div>
            <template v-if="portingOptions.dimariCarrierDto !== null">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    {{
                      portingOptions.dimariCarrierDto.contractExists
                        ? 'mdi-check-circle'
                        : 'mdi-close-circle'
                    }}
                  </v-icon>
                </template>
                <span>
                  {{
                    portingOptions.dimariCarrierDto.contractExists
                      ? 'Vertragspartner'
                      : 'Kein Vertragspartner'
                  }}
                </span>
              </v-tooltip>
            </template>
            <template v-else>
              -
            </template>
          </div>
        </div>
      </div>
    </DisplayContainer>
  </div>
</template>

<script>
import { HTTP } from '@/main/httpClient.js';
import NumberManagementService from './NumberManagementService.vue';
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import DatePicker from '@/components/elements/DatePicker.vue';

import lodash from 'lodash';

export default {
  components: { NumberManagementService, DisplayContainer, DatePicker },
  props: {
    previouslySelectedPortingOptions: {
      type: Object,
      required: false,
      default: null
    }
  },
  data: () => ({
    portingOptions: null,
    fetchedCarrierList: [],
    carrierErrorMessage: null,
    carrierListLoading: false,
    validationErrors: null
  }),
  computed: {
    numberListValid() {
      return this.portingOptions.phoneNumbers.length > 0;
    }
  },
  created: function () {
    if (this.previouslySelectedPortingOptions) {
      this.portingOptions = lodash.cloneDeep(
        this.previouslySelectedPortingOptions
      );
    } else {
      this.portingOptions = this.buildDefaultPortingOptions();
    }
    this.validationErrors = this.buildInitialValidationErrors(
      this.portingOptions
    );
    this.emitCurrentState();
    this.loadCarrierList();
  },
  methods: {
    buildDefaultPortingOptions() {
      return {
        portPhoneNumbersWithCancellation: true,
        portAllPhoneNumbers: true,
        dimariCarrierDto: null,
        phoneNumbers: []
      };
    },
    buildInitialValidationErrors(portingOptions) {
      return {
        _validationErrors: this.checkForMissingFields(portingOptions),
        portPhoneNumbersWithCancellation: { _validationErrors: [] },
        portAllPhoneNumbers: { _validationErrors: [] },
        dimariCarrierDto: { _validationErrors: [] },
        phoneNumbers: { _validationErrors: [] }
      };
    },

    checkForMissingFields(portingOptions) {
      const errorMessages = [];
      if (!portingOptions.phoneNumbers.length === 0) {
        errorMessages.push(
          'Es muss mindestens eine Telefonnummer angegeben werden.'
        );
      }
      return errorMessages;
    },
    emitCurrentState() {
      this.validationErrors._validationErrors = this.checkForMissingFields(
        this.portingOptions
      );
      this.$emit('portingOptionsChanged', {
        value: this.portingOptions,
        validationErrors: this.validationErrors
      });
    },
    addNumber(number) {
      this.portingOptions.phoneNumbers.push(number);
      this.emitCurrentState();
    },
    removeNumber(number) {
      const numberIndex = this.portingOptions.phoneNumbers.indexOf(number);
      if (numberIndex !== -1) {
        this.portingOptions.phoneNumbers.splice(numberIndex, 1);
      }
      this.emitCurrentState();
    },
    loadCarrierList() {
      this.carrierListLoading = true;
      HTTP.get('/porting/carrierData')
        .then((resp) => {
          this.fetchedCarrierList = resp.data.map((carrier) => ({
            routingId: carrier.routingId,
            carrierId: carrier.carrierId,
            carrierCode: carrier.carrierCode,
            name: carrier.name,
            contractExists: carrier.contractExists
          }));
        })
        .finally(() => {
          this.carrierListLoading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.v-radio-group ::v-deep .v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
</style>
