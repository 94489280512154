<template>
  <div>
    <ValidationErrorDisplay
      v-if="hasErrors(validationErrors) && displayError"
      :validatitationErrors="validationErrors"
    />
    <div v-if="customerInformation.salutation !== 'COMPANY'">
      <div class="salutation-with-name-wrapper">
        <v-select
          v-model="customerInformation.salutation"
          label="Anrede * "
          persistent-placeholder
          :items="salutationData"
          :item-text="(item) => item.label"
          item-value="data"
          dense
          :disabled="disabled"
          hide-details
          @change="
            if (customerInformation.salutation !== 'COMPANY') {
              customerInformation.company = null;
              validationErrors.company._validationErrors = [];
            }
            emitCurrentState();
          "
        >
        </v-select>

        <v-text-field
          v-model="customerInformation.firstName"
          label="Vorname *"
          :error-messages="validationErrors.firstName._validationErrors"
          dense
          :disabled="disabled"
          persistent-placeholder
          @input="
            {
              validationErrors.firstName._validationErrors =
                validateFirstName($event);
              emitCurrentState();
            }
          "
        ></v-text-field>

        <v-text-field
          v-model="customerInformation.lastName"
          label="Nachname *"
          :error-messages="validationErrors.lastName._validationErrors"
          dense
          :disabled="disabled"
          persistent-placeholder
          @input="
            {
              validationErrors.lastName._validationErrors =
                validateLastName($event);
              emitCurrentState();
            }
          "
        ></v-text-field>
      </div>
      <div class="input-row margin-bottom-1">
        <DatePicker
          v-if="!displayBasicInformation"
          v-model="customerInformation.birthDate"
          label="Geburtsdatum"
          placeholder=""
          :disabled="disabled"
          :maxDate="latestAllowedBirthDay"
          :dense="true"
          @input="
            {
              emitCurrentState();
            }
          "
        />
        <v-text-field
          v-if="!displayBasicInformation"
          v-model="customerInformation.email"
          label="E-Mail"
          :error-messages="validationErrors.email._validationErrors"
          dense
          :disabled="disabled"
          persistent-placeholder
          @input="
            {
              validationErrors.email._validationErrors = validateEmail($event);
              emitCurrentState();
            }
          "
        ></v-text-field>
      </div>
      <div class="input-row">
        <v-text-field
          v-if="!displayBasicInformation"
          id="mobileNumber"
          v-model="customerInformation.cellPhoneNumber"
          :error-messages="validationErrors.cellPhoneNumber._validationErrors"
          label="Mobilnummer"
          dense
          :disabled="disabled"
          persistent-placeholder
          @input="
            {
              validationErrors.cellPhoneNumber._validationErrors =
                validateMobileNumber($event);
              emitCurrentState();
            }
          "
        ></v-text-field>
        <v-text-field
          v-if="!displayBasicInformation"
          v-model="customerInformation.phone"
          label="Telefonnummer"
          :error-messages="validationErrors.phone._validationErrors"
          dense
          :disabled="disabled"
          persistent-placeholder
          @input="
            {
              validationErrors.phone._validationErrors =
                validatePhoneNumber($event);
              emitCurrentState();
            }
          "
        ></v-text-field>
      </div>
    </div>
    <div v-else>
      <div class="salutation-with-company-wrapper">
        <v-select
          v-model="customerInformation.salutation"
          :items="salutationData"
          :item-text="(item) => item.label"
          item-value="data"
          label="Anrede"
          dense
          persistent-placeholder
          :disabled="disabled"
          hide-details
          @change="
            if (customerInformation.salutation !== 'COMPANY') {
              customerInformation.company = null;
              validationErrors.company._validationErrors = [];
            }
            emitCurrentState();
          "
        >
        </v-select>

        <v-text-field
          v-model="customerInformation.company"
          dense
          label="Firma *"
          persistent-placeholder
          :disabled="disabled"
          :error-messages="validationErrors.company._validationErrors"
          @input="
            {
              validationErrors.company._validationErrors =
                validateCompanyName($event);
              emitCurrentState();
            }
          "
        ></v-text-field>
      </div>
      <div class="input-row margin-bottom-1">
        <v-text-field
          v-model="customerInformation.firstName"
          label="Vorname Ansprechpartner"
          :error-messages="validationErrors.firstName._validationErrors"
          dense
          :disabled="disabled"
          persistent-placeholder
          @input="
            {
              validationErrors.firstName._validationErrors =
                validateFirstName($event);
              emitCurrentState();
            }
          "
        ></v-text-field>

        <v-text-field
          v-model="customerInformation.lastName"
          label="Nachname Ansprechpartner"
          :error-messages="validationErrors.lastName._validationErrors"
          dense
          :disabled="disabled"
          persistent-placeholder
          @input="
            {
              validationErrors.lastName._validationErrors =
                validateLastName($event);
              emitCurrentState();
            }
          "
        ></v-text-field>
      </div>

      <div class="input-row">
        <v-text-field
          v-if="!displayBasicInformation"
          id="mobileNumber"
          v-model="customerInformation.cellPhoneNumber"
          dense
          label="Mobilnummer"
          :error-messages="validationErrors.cellPhoneNumber._validationErrors"
          :disabled="disabled"
          persistent-placeholder
          @input="
            {
              validationErrors.cellPhoneNumber._validationErrors =
                validateMobileNumber($event);
              emitCurrentState();
            }
          "
        ></v-text-field>

        <v-text-field
          v-if="!displayBasicInformation"
          v-model="customerInformation.phone"
          dense
          :disabled="disabled"
          label="Telefonnummer"
          :error-messages="validationErrors.phone._validationErrors"
          persistent-placeholder
          @input="
            {
              validationErrors.phone._validationErrors =
                validatePhoneNumber($event);
              emitCurrentState();
            }
          "
        ></v-text-field>
      </div>
      <v-text-field
        v-if="!displayBasicInformation"
        v-model="customerInformation.email"
        dense
        label="E-Mail"
        persistent-placeholder
        :disabled="disabled"
        :error-messages="validationErrors.email._validationErrors"
        @input="
          {
            validationErrors.email._validationErrors = validateEmail($event);
            emitCurrentState();
          }
        "
      ></v-text-field>
    </div>
  </div>
</template>

<script>
import lodash from 'lodash';

import DatePicker from '@/components/elements/DatePicker.vue';
import { LocalDate } from '@js-joda/core';
import { hasErrors } from '../../orderCreation/ValidationError.js';
import ValidationErrorDisplay from '../ValidationErrorDisplay.vue';

import {
  companyIsValid,
  nameIsValid,
  emailIsValid,
  phoneNumberIsValid
} from '@/util/ValidationUtil.js';
export default {
  name: 'CustomerInformationSelection',
  components: {
    DatePicker,
    ValidationErrorDisplay
  },
  props: {
    previouslySelectedCustomerInformation: {
      type: Object,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    displayError: {
      type: Boolean,
      required: false,
      default: false
    },
    // Only display basic contact information
    displayBasicInformation: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    test: null,
    salutationData: [
      { data: 'MISTER', label: 'Herr' },
      { data: 'MISS', label: 'Frau' },
      { data: 'COUPLE', label: 'Eheleute' },
      { data: 'DIVERSE', label: 'Diverse' },
      { data: 'COMPANY', label: 'Firma' }
    ],

    customerInformation: null,
    validationErrors: null,
    earliestAllowedBirthDay: LocalDate.now().minusYears(130).toString(),
    latestAllowedBirthDay: LocalDate.now().minusYears(18).toString()
  }),
  created() {
    if (this.previouslySelectedCustomerInformation) {
      this.customerInformation = lodash.cloneDeep(
        this.previouslySelectedCustomerInformation
      );
    } else {
      this.customerInformation = this.buildDefaultCustomerInformation();
    }
    this.validationErrors = this.buildInitialValidationErrors(
      this.customerInformation
    );
    this.emitCurrentState();
  },
  methods: {
    emitCurrentState() {
      this.validationErrors._validationErrors = this.checkForMissingFields(
        this.customerInformation
      );
      this.updateValidationErrorsOfTransientFields();
      this.$emit('customerInformationChanged', {
        value: this.customerInformation,
        validationErrors: this.validationErrors
      });
    },
    hasErrors(validationErrors) {
      return hasErrors(validationErrors);
    },
    buildDefaultCustomerInformation() {
      return {
        salutation: null,
        firstName: null,
        lastName: null,
        company: null,
        email: null,
        birthDate: null,
        phoneNumber: null,
        mobileNumber: null
      };
    },
    buildInitialValidationErrors(customerInformation) {
      return {
        _validationErrors: this.checkForMissingFields(customerInformation),
        salutation: { _validationErrors: [] },
        firstName: {
          _validationErrors: this.validateFirstName(
            customerInformation.firstName
          )
        },
        lastName: {
          _validationErrors: this.validateLastName(customerInformation.lastName)
        },
        company: {
          _validationErrors: this.validateCompanyName(
            customerInformation.company
          )
        },
        email: {
          _validationErrors: this.validateEmail(customerInformation.email)
        },
        birthDate: {
          _validationErrors: this.validateBirthDay(
            customerInformation.birthDate
          )
        },
        phone: {
          _validationErrors: this.validatePhoneNumber(customerInformation.phone)
        },
        cellPhoneNumber: {
          _validationErrors: this.validateMobileNumber(
            customerInformation.cellPhoneNumber
          )
        }
      };
    },
    checkForMissingFields(customerInformation) {
      const errorMessages = [];
      if (customerInformation.salutation === null) {
        errorMessages.push('Es muss eine Anrede ausgewählt werden.');
      } else if (customerInformation.salutation === 'COMPANY') {
        if (!customerInformation.company) {
          errorMessages.push('Es muss eine Firma angegeben werden.');
        }
      } else {
        if (!customerInformation.firstName) {
          errorMessages.push('Es muss ein Vorname angegeben werden.');
        }
        if (!customerInformation.lastName) {
          errorMessages.push('Es muss ein Nachname angegeben werden.');
        }
      }
      return errorMessages;
    },
    updateValidationErrorsOfTransientFields() {
      // TODO: Clear validation errors
    },
    validateFirstName(firstName) {
      return nameIsValid(firstName)
        ? []
        : ['Der Vorname enthält ungültige Zeichen.'];
    },
    validateLastName(lastName) {
      return nameIsValid(lastName)
        ? []
        : ['Der Nachname enthält ungültige Zeichen.'];
    },
    validateCompanyName(company) {
      return companyIsValid(company)
        ? []
        : ['Der Firmenname enthält ungültige Zeichen.'];
    },
    validateEmail(email) {
      return emailIsValid(email) ? [] : ['Die Emailadresse ist ungültig.'];
    },
    validatePhoneNumber(phoneNumber) {
      return phoneNumberIsValid(phoneNumber)
        ? []
        : ['Die Telefonnummer ist ungültig.'];
    },
    validateMobileNumber(mobileNumber) {
      return phoneNumberIsValid(mobileNumber)
        ? []
        : ['Die Mobilfunknummer ist ungültig.'];
    },
    validateBirthDay(birthDay) {
      return []; // TODO: Implement
    }
  }
};
</script>

<style lang="scss" scoped>
.salutation-with-name-wrapper {
  display: grid;
  grid-template-columns: 15% 1fr 1fr;
  grid-gap: var(--goe-spacing-1);
}

.salutation-with-company-wrapper {
  display: grid;
  grid-template-columns: 15% 1fr;
  grid-gap: var(--goe-spacing-1);
}
</style>
