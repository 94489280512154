<template>
  <div>
    <v-data-table
      v-model="selection"
      :headers="[
        { text: 'Anzahl', value: 'orderedQuantity' },
        {
          text: 'Name',
          align: 'start',
          value: 'name',
          width: '25%'
        },
        {
          text: 'Beschreibung',
          value: 'description',
          width: '33%'
        },
        {
          text: isOneTimeProduct === true ? 'Einmaliger Preis' : 'Preis',
          value: 'price',
          width: '10%'
        },
        { text: 'Gültig ab', value: 'validFrom', width: '10%' },
        { text: 'Gültig bis', value: 'validUntil', width: '10%' }
      ]"
      :items="availableProductsWithOrderedQuantities"
      :single-select="singleSelect"
      item-key="id"
      :disabled="disabled"
      dense
      :options="{ itemsPerPage: -1 }"
      hide-default-footer
      show-select
      class="elevation-1"
      @input="emitCurrentState()"
    >
      <template v-slot:header.data-table-select="{}">
      </template>
      <template v-slot:item.data-table-select="{ item, isSelected, select }">
        <v-simple-checkbox
          :value="isSelected"
          :disabled="disabled"
          @input="select($event)"
        ></v-simple-checkbox>
      </template>
      <template v-slot:item.orderedQuantity="{ item }">
        <div v-if="itemIsSelected(item)" class="v-select allign-selection">
          <v-select
            v-model="item.orderedQuantity"
            :items="buildQuantitySelection(item)"
            dense
            hide-details
            type="number"
            @change="emitCurrentState()"
          />
        </div>
      </template>
      <template v-slot:item.description="{ item }">
        <div v-if="item.description">
          {{ item.description }}
        </div>
        <div v-else>
          -
        </div>
      </template>
      <template v-slot:item.price="{ item }">
        <div v-if="item.price">
          {{ item.price }} €
        </div>
        <div v-else>
          -
        </div>
      </template>
      <template v-slot:item.validFrom="{ item }">
        <div v-if="item.validFrom">
          {{ formatLocalDate(item.validFrom) }}
        </div>
        <div v-else>
          -
        </div>
      </template>
      <template v-slot:item.validUntil="{ item }">
        <div v-if="item.validUntil">
          {{ formatLocalDate(item.validUntil) }}
        </div>
        <div v-else>
          -
        </div>
      </template>

      <template slot="no-data">
        Es existieren keine Produkte von diesem Dokumententyp.
      </template>
    </v-data-table>
  </div>
</template>

<script>
import lodash from 'lodash';

import TimeUtility from '@/util/TimeUtility.js';
export default {
  name: 'OrderProductTableForMultipleProducts',
  props: {
    availableProducts: {
      type: Array,
      required: false,
      default: null
    },
    previousProductSelection: {
      type: [Object, Array],
      required: false,
      default: null
    },
    isOneTimeProduct: {
      // Deprecated. Should be replaced by property billing cycle in product. Billing cycle should be used to generate label.
      type: Boolean,
      required: false,
      default: false
    },
    singleSelect: {
      type: Boolean,
      required: true
    },
    disabled: { type: Boolean, required: false, deafult: false }
  },
  data: () => ({
    selection: [],
    availableProductsWithOrderedQuantities: []
  }),
  watch: {
    availableProducts: {
      handler: function (newValue, _) {
        if (newValue) {
          return (this.availableProductsWithOrderedQuantities = newValue.map(
            (item) => {
              let orderedQuantity = 1;
              if (this.itemWasPreviouslySelected(item)) {
                orderedQuantity = this.extractPreviouslySelectedQuantity(item);
              }
              return { ...item, orderedQuantity: orderedQuantity };
            }
          ));
        }
      },
      immediate: true
    }
  },
  created() {
    if (this.previousProductSelection) {
      const selection = lodash.cloneDeep(this.previousProductSelection);
      if (this.singleSelect === true) {
        this.selection = [selection];
      } else {
        this.selection = selection;
      }
    } else {
      this.selection = this.buildDefaultSelection();
    }
  },
  methods: {
    emitCurrentState() {
      if (this.singleSelect === true) {
        let value = null;
        if (this.selection != null && this.selection.length > 0) {
          value = this.selection[0];
        }
        this.$emit('selectionChanged', value);
      } else {
        let value = [];
        if (this.selection) {
          value = this.selection;
        }
        this.$emit('selectionChanged', value);
      }
    },
    buildQuantitySelection(item) {
      const quantitySelection = [];
      for (let i = 1; i <= item.maximalQuantity; i++) {
        quantitySelection.push(i);
      }
      return quantitySelection;
    },
    itemIsSelected(item) {
      return (
        this.selection.filter((filterItem) => filterItem.id === item.id)
          .length > 0
      );
    },
    itemWasPreviouslySelected(item) {
      if (this.singleSelect) {
        return (
          this.previousProductSelection &&
          this.previousProductSelection.id === item.id
        );
      } else {
        return (
          this.previousProductSelection &&
          this.previousProductSelection.filter(
            (filterItem) => filterItem.id === item.id
          ).length > 0
        );
      }
    },
    extractPreviouslySelectedQuantity(item) {
      if (this.singleSelect) {
        return this.previousProductSelection.orderedQuantity;
      } else {
        return this.previousProductSelection
          .filter((filterItem) => filterItem.id === item.id)
          .map((remainingItem) => remainingItem.orderedQuantity)[0];
      }
    },
    formatLocalDate(date) {
      return TimeUtility.formatLocalDate(date);
    },
    buildDefaultSelection() {
      return [];
    }
  }
};
</script>

<style lang="scss" scoped>
.product-selected {
  background-color: #1e9cd8;
  color: white;
}
.internet-product {
  cursor: pointer;
}

.allign-selection {
  display: flex;
  align-items: center;
}

.v-select ::v-deep .v-text-field {
  margin-top: 0px;
  padding-top: 0px;
}
</style>
