import { render, staticRenderFns } from "./CustomerInformationSelection.vue?vue&type=template&id=dcaad9ec&scoped=true&"
import script from "./CustomerInformationSelection.vue?vue&type=script&lang=js&"
export * from "./CustomerInformationSelection.vue?vue&type=script&lang=js&"
import style0 from "./CustomerInformationSelection.vue?vue&type=style&index=0&id=dcaad9ec&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dcaad9ec",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VSelect,VTextField})
