var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"input-row margin-bottom-0_5"},[_c('DataDisplay',{attrs:{"label":"Internet Hauptprodukt"}},[(_vm.selectedProducts.internetMainProduct)?_c('div',[_vm._v(" "+_vm._s(_vm.selectedProducts.internetMainProduct.name)+" ")]):_vm._e()]),_c('DataDisplay',{attrs:{"label":"Internet optionale Produkte"}},[(
          _vm.selectedProducts.internetOptionalProducts &&
            _vm.selectedProducts.internetOptionalProducts.length > 0
        )?_c('div',_vm._l((_vm.selectedProducts.oneTimeServiceProducts),function(product){return _c('div',{key:product.id},[_vm._v(" "+_vm._s(product.name)+" ")])}),0):_vm._e()]),_c('DataDisplay',{attrs:{"label":"Service Produkte"}},[(
          _vm.selectedProducts.oneTimeOrderItemProducts &&
            _vm.selectedProducts.oneTimeOrderItemProducts.length > 0
        )?_c('div',_vm._l((_vm.selectedProducts.oneTimeOrderItemProducts),function(product){return _c('div',{key:product.id},[_vm._v(" "+_vm._s(product.name)+" ")])}),0):_vm._e()]),_c('div'),_c('DataDisplay',{attrs:{"label":"ONT Gerät"}},[(_vm.selectedProducts.ontDeviceProduct)?_c('div',[_vm._v(" "+_vm._s(_vm.selectedProducts.ontDeviceProduct.name)+" ")]):_vm._e()]),_c('DataDisplay',{attrs:{"label":"CPE Gerät"}},[(_vm.selectedProducts.cpeDeviceProduct)?_c('div',[_vm._v(" "+_vm._s(_vm.selectedProducts.cpeDeviceProduct.name)+" ")]):_vm._e()]),_c('DataDisplay',{attrs:{"label":"Telefon Hauptprodukt"}},[(_vm.selectedProducts.voiceMainProduct)?_c('div',[_vm._v(" "+_vm._s(_vm.selectedProducts.voiceMainProduct.name)+" ")]):_vm._e()]),_c('DataDisplay',{attrs:{"label":"Telefonie optionale Produkte"}},[(
          _vm.selectedProducts.voiceOptionalProducts &&
            _vm.selectedProducts.voiceOptionalProducts.length > 0
        )?_c('div',_vm._l((_vm.selectedProducts.voiceOptionalProducts),function(product){return _c('div',{key:product.id},[_vm._v(" "+_vm._s(product.name)+" ")])}),0):_vm._e()]),_c('DataDisplay',{attrs:{"label":"TV Hauptprodukt"}},[(_vm.selectedProducts.tvMainProduct)?_c('div',[_vm._v(" "+_vm._s(_vm.selectedProducts.tvMainProduct.name)+" ")]):_vm._e()]),_c('DataDisplay',{attrs:{"label":"TV optionale Produkte"}},[(
          _vm.selectedProducts.tvOptionalProducts &&
            _vm.selectedProducts.tvOptionalProducts.length > 0
        )?_c('div',_vm._l((_vm.selectedProducts.tvOptionalProducts),function(product){return _c('div',{key:product.id},[_vm._v(" "+_vm._s(product.name)+" ")])}),0):_vm._e()]),_c('DataDisplay',{attrs:{"label":"TV-Gerät"}},[(_vm.selectedProducts.tvDeviceProduct)?_c('div',[_vm._v(" "+_vm._s(_vm.selectedProducts.tvDeviceProduct.name)+" ("+_vm._s(_vm.selectedProducts.tvDeviceProduct.orderedQuantity)+" bestelltes Gerät) ")]):_vm._e()])],1),(_vm.hasErrors(_vm.validationErrorsServiceOrderProducts) === true)?_c('ValidationErrorDisplay',{attrs:{"validatitationErrors":_vm.validationErrorsServiceOrderProducts}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }