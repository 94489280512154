<template>
  <div>
    <DisplayContainer
      v-show="orderType !== 'HOUSECONNECTION'"
      label="Dokumente Diensteauftrag"
      isSubcomponent
    >
      <UploadedDocumentsTable
        :documents="previouslyUploadedFilesServiceOrder"
      />
    </DisplayContainer>
    <DisplayContainer
      v-show="orderType !== 'SERVICE'"
      label="Dokumente Hausanschlussauftrag"
      isSubcomponent
    >
      <UploadedDocumentsTable
        :documents="previouslyUploadedFilesHouseConnectionOrder"
      />
    </DisplayContainer>
  </div>
</template>

<script>
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import UploadedDocumentsTable from '@/components/orderEntry/UploadedDocumentsTable.vue';

export default {
  name: 'UploadedDocumentsSummary',
  components: {
    DisplayContainer,
    UploadedDocumentsTable
  },
  props: {
    orderType: { type: String, required: true },
    previouslyUploadedFilesServiceOrder: {
      type: Array,
      required: false,
      default: () => []
    },
    previouslyUploadedFilesHouseConnectionOrder: {
      type: Array,
      required: false,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped></style>
