<template>
  <div>
    <div class="input-row margin-bottom-0_5">
      <DataDisplay label="Internet Hauptprodukt">
        <div v-if="selectedProducts.internetMainProduct">
          {{ selectedProducts.internetMainProduct.name }}
        </div>
      </DataDisplay>
      <DataDisplay label="Internet optionale Produkte">
        <div
          v-if="
            selectedProducts.internetOptionalProducts &&
              selectedProducts.internetOptionalProducts.length > 0
          "
        >
          <div
            v-for="product in selectedProducts.oneTimeServiceProducts"
            :key="product.id"
          >
            {{ product.name }}
          </div>
        </div>
      </DataDisplay>
      <DataDisplay label="Service Produkte">
        <div
          v-if="
            selectedProducts.oneTimeOrderItemProducts &&
              selectedProducts.oneTimeOrderItemProducts.length > 0
          "
        >
          <div
            v-for="product in selectedProducts.oneTimeOrderItemProducts"
            :key="product.id"
          >
            {{ product.name }}
          </div>
        </div>
      </DataDisplay>
      <div></div>
      <DataDisplay label="ONT Gerät">
        <div v-if="selectedProducts.ontDeviceProduct">
          {{ selectedProducts.ontDeviceProduct.name }}
        </div>
      </DataDisplay>
      <DataDisplay label="CPE Gerät">
        <div v-if="selectedProducts.cpeDeviceProduct">
          {{ selectedProducts.cpeDeviceProduct.name }}
        </div>
      </DataDisplay>
      <DataDisplay label="Telefon Hauptprodukt">
        <div v-if="selectedProducts.voiceMainProduct">
          {{ selectedProducts.voiceMainProduct.name }}
        </div>
      </DataDisplay>
      <DataDisplay label="Telefonie optionale Produkte">
        <div
          v-if="
            selectedProducts.voiceOptionalProducts &&
              selectedProducts.voiceOptionalProducts.length > 0
          "
        >
          <div
            v-for="product in selectedProducts.voiceOptionalProducts"
            :key="product.id"
          >
            {{ product.name }}
          </div>
        </div>
      </DataDisplay>
      <DataDisplay label="TV Hauptprodukt">
        <div v-if="selectedProducts.tvMainProduct">
          {{ selectedProducts.tvMainProduct.name }}
        </div>
      </DataDisplay>
      <DataDisplay label="TV optionale Produkte">
        <div
          v-if="
            selectedProducts.tvOptionalProducts &&
              selectedProducts.tvOptionalProducts.length > 0
          "
        >
          <div
            v-for="product in selectedProducts.tvOptionalProducts"
            :key="product.id"
          >
            {{ product.name }}
          </div>
        </div>
      </DataDisplay>
      <DataDisplay label="TV-Gerät">
        <div v-if="selectedProducts.tvDeviceProduct">
          {{ selectedProducts.tvDeviceProduct.name }} ({{
            selectedProducts.tvDeviceProduct.orderedQuantity
          }}
          bestelltes Gerät)
        </div>
      </DataDisplay>
    </div>
    <ValidationErrorDisplay
      v-if="hasErrors(validationErrorsServiceOrderProducts) === true"
      :validatitationErrors="validationErrorsServiceOrderProducts"
    />
  </div>
</template>

<script>
import lodash from 'lodash';

import OrderProductTable from '../productSelection/OrderProductTable.vue';
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import DataDisplay from '@/components/elements/DataDisplay.vue';
import NormalButton from '@/components/elements/NormalButton.vue';
import Loader from '@/components/Loader.vue';
import DatePicker from '@/components/elements/DatePicker.vue';
import ValidationErrorDisplay from '../ValidationErrorDisplay.vue';
import { hasErrors } from '../ValidationError.js';
export default {
  name: 'ServiceOrderProductSummary',
  components: {
    DisplayContainer,
    DataDisplay,
    NormalButton,
    Loader,
    DatePicker,
    OrderProductTable,
    ValidationErrorDisplay
  },
  props: {
    previouslySelectedProducts: {
      type: Object,
      required: false,
      default: null
    },
    validationErrorsServiceOrderProducts: { type: Object, required: true }
  },
  data: () => ({
    selectedProducts: null
  }),
  created() {
    if (this.previouslySelectedProducts) {
      this.selectedProducts = lodash.cloneDeep(this.previouslySelectedProducts);
    }
  },
  methods: {
    hasErrors(validationErrors) {
      return hasErrors(validationErrors);
    }
  }
};
</script>

<style lang="scss" scoped></style>
