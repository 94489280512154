<template>
  <div>
    <v-data-table
      v-model="selection"
      :headers="[
        {
          text: 'Name',
          align: 'start',
          value: 'name',
          width: '25%'
        },
        {
          text: 'Beschreibung',
          value: 'description',
          width: '33%'
        },
        {
          text: isOneTimeProduct === true ? 'Einmaliger Preis' : 'Preis',
          value: 'price',
          width: '12%'
        },
        { text: 'Gültig ab', value: 'validFrom', width: '12%' },
        { text: 'Gültig bis', value: 'validUntil', width: '15%' }
      ]"
      :items="availableProducts"
      :single-select="singleSelect"
      item-key="id"
      :disabled="disabled"
      dense
      :options="{ itemsPerPage: -1 }"
      hide-default-footer
      show-select
      class="elevation-1"
      @input="emitCurrentState()"
    >
      <template v-slot:header.data-table-select="{}">
      </template>
      <template v-slot:item.data-table-select="{ item, isSelected, select }">
        <v-simple-checkbox
          :value="isSelected"
          :disabled="disabled"
          @input="select($event)"
        ></v-simple-checkbox>
      </template>
      <template v-slot:item.description="{ item }">
        <div v-if="item.description">
          {{ item.description }}
        </div>
        <div v-else>
          -
        </div>
      </template>
      <template v-slot:item.price="{ item }">
        <div v-if="item.price">
          {{ item.price }} €
        </div>
        <div v-else>
          -
        </div>
      </template>
      <template v-slot:item.validFrom="{ item }">
        <div v-if="item.validFrom">
          {{ formatLocalDate(item.validFrom) }}
        </div>
        <div v-else>
          -
        </div>
      </template>
      <template v-slot:item.validUntil="{ item }">
        <div v-if="item.validUntil">
          {{ formatLocalDate(item.validUntil) }}
        </div>
        <div v-else>
          -
        </div>
      </template>
      <template slot="no-data">
        Es existieren keine Produkte von diesem Dokumententyp.
      </template>
    </v-data-table>
  </div>
</template>

<script>
import lodash from 'lodash';

import TimeUtility from '@/util/TimeUtility.js';
export default {
  name: 'OrderProductTable',
  props: {
    availableProducts: {
      type: Array,
      required: false,
      default: null
    },
    previousProductSelection: {
      type: [Object, Array],
      required: false,
      default: null
    },
    isOneTimeProduct: {
      // Deprecated. Should be replaced by property billing cycle in product. Billing cycle should be used to generate label.
      type: Boolean,
      required: false,
      default: false
    },
    singleSelect: {
      type: Boolean,
      required: true
    },
    disabled: { type: Boolean, required: false, deafult: false }
  },
  data: () => ({
    selection: []
  }),
  created() {
    if (this.previousProductSelection) {
      const selection = lodash.cloneDeep(this.previousProductSelection);
      if (this.singleSelect === true) {
        this.selection = [selection];
      } else {
        this.selection = selection;
      }
    } else {
      this.selection = this.buildDefaultSelection();
    }
  },
  methods: {
    emitCurrentState() {
      if (this.singleSelect === true) {
        let value = null;
        if (this.selection != null && this.selection.length > 0) {
          value = this.selection[0];
        }
        this.$emit('selectionChanged', value);
      } else {
        let value = [];
        if (this.selection) {
          value = this.selection;
        }
        this.$emit('selectionChanged', value);
      }
    },
    formatLocalDate(date) {
      return TimeUtility.formatLocalDate(date);
    },
    buildDefaultSelection() {
      return [];
    }
  }
};
</script>

<style lang="scss" scoped>
.product-selected {
  background-color: #1e9cd8;
  color: white;
}
.internet-product {
  cursor: pointer;
}
</style>
