<template>
  <div>
    <DisplayContainer
      label=" Einzelverbindungsnachweise (EVN) "
      :isSubcomponent="true"
    >
      <div class="input-row">
        <DataDisplay label="Art des EVN">
          {{
            itemizedBillTypes[serviceOrderOptions.itemizedBillsGenerationType]
          }}
        </DataDisplay>
      </div>
    </DisplayContainer>
    <DisplayContainer
      label="Telefonbucheintrag (kostenlos) "
      :isSubcomponent="true"
    >
      <ValidationErrorDisplay
        v-if="hasErrors(validationErrors.phoneBookOptions) === true"
        :validatitationErrors="
          flattenAllErrors(validationErrors.phoneBookOptions)
        "
      />
      <div class="input-row">
        <DataDisplay label="Art des Telefonbucheintrags">
          <div>
            {{
              phoneBookRecordTypes[
                serviceOrderOptions.phoneBookOptions.phoneBookRecordType
              ]
            }}
          </div>
        </DataDisplay>
        <DataDisplay
          v-if="
            serviceOrderOptions.phoneBookOptions.phoneBookRecordType !==
              'NO_ENTRY'
          "
          label="Herausgabe von Teilnehmerdaten für die Rückwärtssuche wird zugestimmt"
        >
          <div>
            {{
              serviceOrderOptions.phoneBookOptions.allowPhoneNumberSearch
                ? 'Ja'
                : 'Nein'
            }}
          </div>
        </DataDisplay>
        <NameDisplay
          v-show="
            serviceOrderOptions.phoneBookOptions.phoneBookRecordType !==
              'NO_ENTRY'
          "
          :addressDto="
            serviceOrderOptions.phoneBookOptions.phoneBookEntryAddress
              .customerInformation
          "
        />
        <AddressDisplay
          v-show="
            serviceOrderOptions.phoneBookOptions.phoneBookRecordType !==
              'NO_ENTRY'
          "
          :addressDto="
            serviceOrderOptions.phoneBookOptions.phoneBookEntryAddress.address
          "
        />
      </div>
    </DisplayContainer>
    <DisplayContainer label="Rechnungsversand" :isSubcomponent="true">
      <div class="input-row">
        <DataDisplay label="Rechnungsversand">
          <div>
            {{
              invoiceShippingMethods[serviceOrderOptions.invoiceShippingMethod]
            }}
          </div>
        </DataDisplay>
      </div>
    </DisplayContainer>
    <DisplayContainer
      label="Löschung der Verkehrsdaten von Telefonverbindungen "
      :isSubcomponent="true"
    >
      <div class="input-row">
        <DataDisplay label="Löschung der Verkehrsdaten von Telefonverbindungen">
          <div>
            {{
              storingPeriodsOfConnectionInformation[
                serviceOrderOptions.storingPeriodOfConnectionInformation
              ]
            }}
          </div>
        </DataDisplay>
      </div>
    </DisplayContainer>
    <DisplayContainer
      label="Information zu Produkten, Dienstleistungen und Unternehmensgeschehen "
      :isSubcomponent="true"
    >
      <div class="input-row">
        <DataDisplay label="Beratungseinwilligung">
          <div>
            {{
              serviceOrderOptions.allowAdvertisement
                ? 'Wurde erteilt'
                : 'Wurde nicht erteilt'
            }}
          </div>
        </DataDisplay>
      </div>
    </DisplayContainer>

    <DisplayContainer
      label="Neurufnummernvergabe"
      :selectionConfiguration="{
        label: 'Neurufnummernvergabe durchführen',
        isSelected:
          serviceOrderOptions.newPhoneNumberDetails.provideNewPhoneNumbers,
        isFoldable: true,
        disabled: true
      }"
      :isSubcomponent="true"
    >
      <div class="input-row">
        <DataDisplay label="Anzahl neuer Rufnummern">
          {{
            serviceOrderOptions.newPhoneNumberDetails.numberOfNewPhoneNumbers
          }}
        </DataDisplay>
        <DateDisplay
          label="Anschaltdatum"
          :date="
            serviceOrderOptions.newPhoneNumberDetails.requestedProvidingDate
          "
        />
      </div>
    </DisplayContainer>
    <DisplayContainer
      :selectionConfiguration="{
        label: 'Übernahme eines bestehenden Anschlusses inkl. Rufnummer',
        isSelected: serviceOrderOptions.performPhoneNumberPorting,
        isFoldable: true,
        disabled: true
      }"
      label="Portierung Durchführen"
      :isSubcomponent="true"
    >
      <PortingOptionsSummary
        :previouslySelectedPortingOptions="serviceOrderOptions.portingDetails"
        :validationErrorsPortingOptions="validationErrors.portingDetails"
      />
    </DisplayContainer>
  </div>
</template>

<script>
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import PortingOptionsSummary from './PortingOptionsSummary.vue';
import DateDisplay from '@/components/dataDisplays/DateDisplay.vue';
import AddressDisplay from '@/components/dataDisplays/AddressDisplay.vue';
import NameDisplay from '@/components/dataDisplays/NameDisplay.vue';

import DataDisplay from '@/components/elements/DataDisplay.vue';
import ServiceOrderOptionsUtil from '../ServerOrderOptionsUtil.js';
import ValidationErrorDisplay from '../ValidationErrorDisplay.vue';
import { hasErrors, flattenAllErrors } from '../ValidationError.js';
import lodash from 'lodash';

export default {
  name: 'ServiceOrderOptionsSummary',
  components: {
    DisplayContainer,
    PortingOptionsSummary,
    DataDisplay,
    DateDisplay,
    ValidationErrorDisplay,
    AddressDisplay,
    NameDisplay
  },
  props: {
    previouslySelectedServiceOrderOptions: {
      type: Object,
      required: true
    },
    validationErrorsServiceOrderOptions: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    itemizedBillTypes: ServiceOrderOptionsUtil.itemizedBillTypes,
    invoiceShippingMethods: ServiceOrderOptionsUtil.invoiceShippingMethods,
    phoneBookRecordTypes: ServiceOrderOptionsUtil.phoneBookRecordTypes,
    storingPeriodsOfConnectionInformation:
      ServiceOrderOptionsUtil.storingPeriodsOfConnectionInformation,
    serviceOrderOptions: null,
    validationErrors: null
  }),
  created: function () {
    this.serviceOrderOptions = lodash.cloneDeep(
      this.previouslySelectedServiceOrderOptions
    );
    this.validationErrors = lodash.cloneDeep(
      this.validationErrorsServiceOrderOptions
    );
  },
  methods: {
    hasErrors(validationErrors) {
      return hasErrors(validationErrors);
    },
    flattenAllErrors(validationErrors) {
      return flattenAllErrors(validationErrors);
    }
  }
};
</script>

<style lang="scss" scoped>
.display-body {
  display: grid;
  grid-template-columns: max-content auto;
}
</style>
