<template>
  <div>
    <ValidationErrorDisplay
      v-if="hasErrors(validationErrors) && displayError"
      :validatitationErrors="validationErrors"
    />
    <div class="input-row margin-bottom-1">
      <v-text-field
        v-model="sepaMandate.owner"
        label="Kontoinhaber *"
        :error-messages="validationErrors.owner._validationErrors"
        dense
        :disabled="disabled"
        persistent-placeholder
        @input="
          {
            validationErrors.owner._validationErrors = validateOwner($event);
            currentStateChanged();
          }
        "
      ></v-text-field>
      <v-text-field
        v-model="sepaMandate.iban"
        label="IBAN *"
        :error-messages="validationErrors.iban._validationErrors"
        dense
        :disabled="disabled"
        persistent-placeholder
        @change="
          {
            validationErrors.iban._validationErrors = validateIban($event);
            currentStateChanged();
            getBic($event);
          }
        "
      ></v-text-field>
    </div>

    <div class="input-row">
      <v-text-field
        v-model="sepaMandate.bank"
        label="Kreditinstitut"
        :error-messages="validationErrors.bank._validationErrors"
        dense
        :disabled="disabled"
        persistent-placeholder
        @input="
          {
            validationErrors.bank._validationErrors = validateBank($event);
            currentStateChanged();
          }
        "
      ></v-text-field>
      <v-text-field
        v-model="sepaMandate.bic"
        label="BIC"
        :error-messages="validationErrors.bic._validationErrors"
        dense
        :disabled="disabled"
        persistent-placeholder
        @input="
          {
            validationErrors.bic._validationErrors = validateBic($event);
            currentStateChanged();
          }
        "
      ></v-text-field>
    </div>
  </div>
</template>
<script>
import lodash from 'lodash';
import { isValidIBAN } from 'ibantools';
import axios from 'axios';

import { companyIsValid } from '@/util/ValidationUtil.js';
import { hasErrors } from '../../orderCreation/ValidationError.js';
import ValidationErrorDisplay from '../ValidationErrorDisplay.vue';
export default {
  components: {
    ValidationErrorDisplay
  },
  props: {
    customerInformation: {
      type: Object,
      required: false,
      default: null
    },
    previouslySelectedSepaMandate: {
      type: Object,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    displayError: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    sepaMandate: null,
    validationErrors: null,
    ibanURL: 'https://web.acoo.de/api/iban/'
  }),
  watch: {
    'customerInformation.salutation': {
      handler: function (newValue, _) {
        if (newValue) {
          this.sepaMandate.owner = this.buildAccountName(
            newValue,
            this.customerInformation.firstName,
            this.customerInformation.lastName,
            this.customerInformation.company
          );
          this.validationErrors._validationErrors = this.checkForMissingFields(
            this.sepaMandate
          );
          this.validationErrors.owner._validationErrors = this.validateOwner(
            this.sepaMandate.owner
          );
        }
      }
    },
    'customerInformation.firstName': {
      handler: function (newValue, _) {
        if (newValue) {
          this.sepaMandate.owner = this.buildAccountName(
            this.customerInformation.salutation,
            newValue,
            this.customerInformation.lastName,
            this.customerInformation.company
          );
          this.validationErrors._validationErrors = this.checkForMissingFields(
            this.sepaMandate
          );
          this.validationErrors.owner._validationErrors = this.validateOwner(
            this.sepaMandate.owner
          );
        }
      }
    },
    'customerInformation.lastName': {
      handler: function (newValue, _) {
        if (newValue) {
          this.sepaMandate.owner = this.buildAccountName(
            this.customerInformation.salutation,
            this.customerInformation.firstName,
            newValue,
            this.customerInformation.company
          );
          this.validationErrors._validationErrors = this.checkForMissingFields(
            this.sepaMandate
          );
          this.validationErrors.owner._validationErrors = this.validateOwner(
            this.sepaMandate.owner
          );
        }
      }
    },
    'customerInformation.company': {
      handler: function (newValue, _) {
        if (newValue) {
          this.sepaMandate.owner = this.buildAccountName(
            this.customerInformation.salutation,
            this.customerInformation.firstName,
            this.customerInformation.lastName,
            newValue
          );
          this.validationErrors._validationErrors = this.checkForMissingFields(
            this.sepaMandate
          );
          this.validationErrors.owner._validationErrors = this.validateOwner(
            this.sepaMandate.owner
          );
        }
      }
    }
  },
  created() {
    if (this.$props.previouslySelectedSepaMandate) {
      this.sepaMandate = lodash.cloneDeep(
        this.$props.previouslySelectedSepaMandate
      );
    } else {
      this.sepaMandate = this.buildDefaultSepaMandate();
    }
    this.validationErrors = this.buildInitialValidationErrors(this.sepaMandate);
    this.currentStateChanged();
  },
  methods: {
    buildDefaultSepaMandate() {
      return {
        owner: null,
        bank: null,
        iban: null,
        bic: null
      };
    },
    hasErrors(validationErrors) {
      return hasErrors(validationErrors);
    },
    buildInitialValidationErrors(sepaMandate) {
      return {
        _validationErrors: this.checkForMissingFields(sepaMandate),
        owner: { _validationErrors: this.validateOwner(sepaMandate.owner) },
        bank: { _validationErrors: this.validateBank(sepaMandate.bank) },
        iban: { _validationErrors: this.validateIban(sepaMandate.iban) },
        bic: { _validationErrors: this.validateBic(sepaMandate.bic) }
      };
    },
    currentStateChanged() {
      this.validationErrors._validationErrors = this.checkForMissingFields(
        this.sepaMandate
      );
      this.$emit('sepaMandateChanged', {
        value: this.sepaMandate,
        validationErrors: this.validationErrors
      });
    },

    checkForMissingFields(sepaMandate) {
      const errorMessages = [];
      if (!sepaMandate.iban) {
        errorMessages.push('Es muss ein IBAN angegeben werden.');
      }
      if (!sepaMandate.owner) {
        errorMessages.push('Es muss ein Besitzer angegeben werden.');
      }
      return errorMessages;
    },
    getBic: lodash.debounce(
      function (iban) {
        if (this.validateIban(iban).length === 0 && iban.length > 0) {
          axios
            .post(this.ibanURL + iban, {
              headers: this.headers
            })
            .then((response) => {
              if (response.data.bankData.length > 0) {
                this.sepaMandate.bic = response.data.bankData[0].bic;
                this.sepaMandate.bank = response.data.bankData[0].description;
              }
              this.currentStateChanged();
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => (self.loading = false));
        }
      },
      500,
      { leading: true }
    ),
    addErrorMessagesToSepaMandate(sepaMandate) {
      return sepaMandate;
    },
    validateOwner(name) {
      return companyIsValid(name)
        ? []
        : ['Der Name des Kontoinhabers enthält ungültige Zeichen.'];
    },
    validateBank(name) {
      return companyIsValid(name)
        ? []
        : ['Der Name der Bank enthält ungültige Zeichen.'];
    },
    validateBic(name) {
      return !name ||
        name === '' ||
        /[a-zA-Z]{6}[0-9a-zA-Z]{2}([0-9a-zA-Z]{3})?$/.test(name)
        ? []
        : ['Der BIC ist ungültig.'];
    },
    validateIban(name) {
      return !name || name === '' || isValidIBAN(name)
        ? []
        : ['Der IBAN ist ungültig.'];
    },
    buildAccountName(salutation, firstName, lastName, company) {
      if (salutation === 'COMPANY' && company) {
        return company;
      } else {
        if (firstName && lastName) {
          return `${firstName} ${lastName}`;
        } else if (firstName) {
          return firstName;
        } else if (lastName) {
          return lastName;
        } else {
          return null;
        }
      }
    }
  }
};
</script>

<style></style>
