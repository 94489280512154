var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.label !== null)?_c('div',{class:['label', _vm.invalid ? 'label-error' : '']},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',[_c('div',{staticClass:"table-input-wrapper"},[_c('v-text-field',{staticClass:"table-input",attrs:{"filled":"","hide-details":"","dense":"","single-line":"","background-color":"white","label":"Neue Rufnummer","placeholder":"Neue Rufnummer...","disabled":_vm.disabled,"rules":[
          function (val) { return (!!val && !isNaN(val)) ||
            'Die Telefonnummer muss numerisch sein!'; },
          function (val) { return (!!val && val.startsWith('0')) ||
            'Die Telefonnummer muss mit 0 beginnen!'; },
          function (val) { return (!!val && val.length >= 6 && val.length <= 14) ||
            'Die Telefonnummer muss zwischen 6 und 14 Zeichen lang sein!'; }
        ]},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.tableInputValid ? _vm.addNumber() : null}},model:{value:(_vm.newNumberInput),callback:function ($$v) {_vm.newNumberInput=$$v},expression:"newNumberInput"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-add-number",attrs:{"color":"primary","depressed":"","disabled":!_vm.tableInputValid || _vm.numberListLoading || _vm.disabled,"loading":_vm.numberListLoading},on:{"click":function($event){return _vm.addNumber()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus-thick")])],1)]}}])},[_c('span',[_vm._v("Rufnummer hinzufügen")])])],1),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[(_vm.numberList.length > 0)?_vm._l((_vm.numberList),function(number){return _c('tr',{key:number},[_c('td',[_vm._v(" "+_vm._s(number)+" ")]),_c('td',[_c('v-btn',{attrs:{"icon":"","disabled":_vm.disabled},on:{"click":function($event){return _vm.deleteNumber(number)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)])}):_c('tr',[_c('td',[_c('v-icon',{attrs:{"color":"var(--goe-color-error)"}},[_vm._v(" mdi-lock-alert ")]),_vm._v(" Es muss mindestens eine Nummer angegeben werden! ")],1)])],2)]},proxy:true}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }