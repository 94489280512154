<template>
  <div>
    <div v-if="label !== null" :class="['label', invalid ? 'label-error' : '']">
      {{ label }}
    </div>
    <div>
      <div class="table-input-wrapper">
        <v-text-field
          v-model="newNumberInput"
          class="table-input"
          filled
          hide-details
          dense
          single-line
          background-color="white"
          label="Neue Rufnummer"
          placeholder="Neue Rufnummer..."
          :disabled="disabled"
          :rules="[
            (val) =>
              (!!val && !isNaN(val)) ||
              'Die Telefonnummer muss numerisch sein!',
            (val) =>
              (!!val && val.startsWith('0')) ||
              'Die Telefonnummer muss mit 0 beginnen!',
            (val) =>
              (!!val && val.length >= 6 && val.length <= 14) ||
              'Die Telefonnummer muss zwischen 6 und 14 Zeichen lang sein!'
          ]"
          @keydown.enter="tableInputValid ? addNumber() : null"
        />
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="btn-add-number"
              color="primary"
              v-bind="attrs"
              depressed
              :disabled="!tableInputValid || numberListLoading || disabled"
              :loading="numberListLoading"
              v-on="on"
              @click="addNumber()"
            >
              <v-icon>mdi-plus-thick</v-icon>
            </v-btn>
          </template>
          <span>Rufnummer hinzufügen</span>
        </v-tooltip>
      </div>

      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <template v-if="numberList.length > 0">
              <tr v-for="number in numberList" :key="number">
                <td>
                  {{ number }}
                </td>
                <td>
                  <v-btn
                    icon
                    :disabled="disabled"
                    @click="deleteNumber(number)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
            <tr v-else>
              <td>
                <v-icon color="var(--goe-color-error)">
                  mdi-lock-alert
                </v-icon>
                Es muss mindestens eine Nummer angegeben werden!
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>
<script>
import DialogButtonWithTooltip from '@/components/elements/DialogButtonWithTooltip.vue';

export default {
  // TODO: WARNING: This component is a duplicate of the component NumberManagement.vue
  name: 'NumberManagement',
  components: {
    DialogButtonWithTooltip
  },
  props: {
    label: {
      type: String,
      required: false,
      default: null
    },
    numberList: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    invalid: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    newNumberInput: null,
    numberListLoading: false
  }),
  computed: {
    tableInputValid() {
      return (
        !!this.newNumberInput &&
        !isNaN(this.newNumberInput) &&
        this.newNumberInput.startsWith('0') &&
        this.newNumberInput.length >= 6 &&
        this.newNumberInput.length <= 14
      );
    }
  },
  methods: {
    addNumber() {
      this.numberListLoading = true;
      this.$emit('numberAdded', this.newNumberInput);
      this.numberListLoading = false;
      this.newNumberInput = null;
    },
    deleteNumber(number) {
      this.$emit('numberRemoved', number);
    }
  }
};
</script>
<style scoped>
.v-data-table table > tbody > tr > td {
  font-size: var(--goe-fontSize-normal) !important;
}

tr > td:nth-child(2) {
  min-width: min-content;
  width: 5%;
}

div.table-input-wrapper {
  display: grid;
  grid-template-columns: auto max-content;
  grid-gap: var(--goe-spacing-0);
  margin-bottom: var(--goe-spacing-0) !important;
}

.table-input {
  width: 100%;
  border-top-right-radius: 0px;
  font-size: var(--goe-fontSize-normal);
}

.table-input >>> .v-input__slot {
  padding: 0px 16px !important;
}

.btn-add-number {
  height: 41px !important;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

td >>> .btn-remove-number:hover {
  color: red;
}

.label.label-error {
  color: red;
}
</style>
