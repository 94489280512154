var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.hasErrors(_vm.validationErrors) && _vm.displayError)?_c('ValidationErrorDisplay',{attrs:{"validatitationErrors":_vm.validationErrors}}):_vm._e(),_c('CustomerInformationSelection',{attrs:{"disabled":_vm.disabled,"previouslySelectedCustomerInformation":_vm.addressInformation.customerInformation},on:{"customerInformationChanged":function($event){{
        _vm.addressInformation.customerInformation = $event.value;
        _vm.validationErrors.customerInformation = $event.validationErrors;
        _vm.updateCurrentState();
      }}}}),_c('AddressAutocomplete',{attrs:{"disabled":_vm.disabled,"previouslySelectedAddress":_vm.addressInformation.address},on:{"update":function($event){{
        _vm.addressInformation.address = $event.value;
        _vm.validationErrors.address = $event.validationErrors;
        _vm.updateCurrentState();
      }}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }