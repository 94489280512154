var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.installationAddressCanBeInserted)?_c('DisplayContainer',{attrs:{"label":"Kontaktdaten","isSubcomponent":""}},[_c('div',{staticClass:"input-row"},[_c('NameDisplay',{attrs:{"addressDto":_vm.customerData.customerInformation}}),_c('div'),_c('ContactInformationDisplay',{attrs:{"addressDto":_vm.customerData.customerInformation}}),(_vm.customerData.customerInformation.salutation != 'COMPANY')?_c('DateDisplay',{attrs:{"label":"Geburtsdatum","date":_vm.customerData.customerInformation.birthDate}}):_vm._e()],1)]):_vm._e(),_c('DisplayContainer',{attrs:{"label":"Installationsadresse","isSubcomponent":""}},[_c('AddressDisplay',{attrs:{"addressDto":_vm.customerData.installationAddress}})],1),(_vm.ibanCanBeInserted)?_c('DisplayContainer',{attrs:{"isSubcomponent":"","label":"Lastschriftmandat","selectionConfiguration":{
      label: 'Zahlung per Lastschriftmandat',
      isSelected: _vm.customerData.sepaMandateIsSelected,
      isFoldable: true,
      disabled: true
    }}},[_c('div',{staticClass:"margin-bottom-0_5"},[(_vm.ibanCanBeInserted)?_c('SepaMandateDisplay',{attrs:{"sepaMandateDto":_vm.customerData.sepaMandate}}):_vm._e()],1),(_vm.hasErrors(_vm.validationErrorsCustomerData.sepaMandate) === true)?_c('ValidationErrorDisplay',{attrs:{"validatitationErrors":_vm.validationErrorsCustomerData.sepaMandate}}):_vm._e()],1):_vm._e(),(_vm.invoiceAddressCanBeInserted)?_c('DisplayContainer',{staticClass:"margin-top-0_5",attrs:{"label":"Rechnungsadresse","isSubcomponent":"","selectionConfiguration":{
      label: 'Abweichend',
      isSelected: _vm.customerData.invoiceAddressIsSelected,
      isFoldable: true,
      disabled: true
    }}},[(_vm.customerData.invoiceAddress)?_c('ContactInformation',{staticClass:"margin-bottom-0_5",attrs:{"addressDto":_vm.buildAddressDto(
          _vm.customerData.invoiceAddress.customerInformation,
          _vm.customerData.invoiceAddress.address
        )}}):_vm._e(),(_vm.hasErrors(_vm.validationErrorsCustomerData.invoiceAddress) === true)?_c('ValidationErrorDisplay',{attrs:{"validatitationErrors":_vm.validationErrorsCustomerData.invoiceAddress}}):_vm._e()],1):_vm._e(),(_vm.ibanCanBeInserted)?_c('DisplayContainer',{staticClass:"margin-top-0_5",attrs:{"label":"Kontaktadresse","isSubcomponent":"","selectionConfiguration":{
      label: 'Abweichend',
      isSelected: _vm.customerData.contactAddressIsSelected,
      isFoldable: true,
      disabled: true
    }}},[(_vm.customerData.contactAddress)?_c('ContactInformation',{staticClass:"margin-bottom-0_5",attrs:{"addressDto":_vm.buildAddressDto(
          _vm.customerData.contactAddress.customerInformation,
          _vm.customerData.contactAddress.address
        )}}):_vm._e(),(_vm.hasErrors(_vm.validationErrorsCustomerData.contactAddress) === true)?_c('ValidationErrorDisplay',{attrs:{"validatitationErrors":_vm.validationErrorsCustomerData.contactAddress}}):_vm._e()],1):_vm._e(),(_vm.invoiceAddressCanBeInserted)?_c('DisplayContainer',{staticClass:"margin-top-0_5",attrs:{"label":"Versandadresse","isSubcomponent":"","selectionConfiguration":{
      label: 'Abweichend',
      isSelected: _vm.customerData.deviatingDeliveryAddressIsSelected,
      isFoldable: true,
      disabled: true
    }}},[(_vm.customerData.deliveryAddress)?_c('ContactInformation',{staticClass:"margin-bottom-0_5",attrs:{"addressDto":_vm.buildAddressDto(
          _vm.customerData.deliveryAddress.customerInformation,
          _vm.customerData.deliveryAddress.address
        )}}):_vm._e(),(_vm.hasErrors(_vm.validationErrorsCustomerData.deliveryAddress) === true)?_c('ValidationErrorDisplay',{attrs:{"validatitationErrors":_vm.validationErrorsCustomerData.deliveryAddress}}):_vm._e()],1):_vm._e(),(_vm.deviationOwnerInformationCanBeInserted)?_c('DisplayContainer',{staticClass:"margin-top-0_5",attrs:{"label":"Grundstückseigentümer","isSubcomponent":"","selectionConfiguration":{
      label: 'Abweichend',
      isSelected: _vm.customerData.deviatingOwnerInformationIsSelected,
      isFoldable: true,
      disabled: true
    }}},[(_vm.customerData.deviatingOwnerInformation)?_c('div',{staticClass:"input-row"},[_c('DataDisplay',{attrs:{"label":"Grundstückseigentümer zahlt Hausanschlussauftrag"}},[_vm._v(" "+_vm._s(_vm.customerData.deviatingOwnerInformation.ownerPaysForHouseConnection ? 'Ja' : 'Nein')+" ")])],1):_vm._e(),(_vm.customerData.deviatingOwnerInformation)?_c('ContactInformation',{staticClass:"margin-bottom-0_5",attrs:{"addressDto":_vm.buildAddressDto(
          _vm.customerData.deviatingOwnerInformation.customerInformation,
          _vm.customerData.deviatingOwnerInformation.address
        )}}):_vm._e(),(_vm.hasErrors(_vm.validationErrorsOwnerInformation) === true)?_c('ValidationErrorDisplay',{attrs:{"validatitationErrors":_vm.validationErrorsOwnerInformation}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }