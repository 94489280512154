<template>
  <div>
    <div class="input-row-max-content-left">
      <v-combobox
        ref="zip"
        v-model="addressIntern.zipCode"
        :items="groupBy(zipItems, 'zip')"
        :loading="loading"
        :search-input.sync="search"
        :disabled="disabled"
        :hint="
          addressIntern.zipCode >= 3
            ? null
            : 'Mindestens drei Ziffern erforderlich.'
        "
        hide-no-data
        label="Postleitzahl *"
        return-object
        persistent-placeholder
        dense
        :counter="5"
        maxLength="5"
        :error-messages="validationErrors.zipCode._validationErrors"
        @input="
          validationErrors.zipCode._validationErrors = validateZipCode($event)
        "
      />

      <v-autocomplete
        v-model="addressIntern.city"
        :items="groupBy(cityItems, 'city')"
        :menu-props="{ maxHeight: '400' }"
        :disabled="disabled"
        label="Ort *"
        hint="Ort auswählen"
        persistent-placeholder
        dense
        hide-no-data
      />
    </div>
    <div
      v-if="alternativeLocationDescriptionIsRequired === false"
      class="input-row-adress-data margin-top-1"
    >
      <v-autocomplete
        v-model="addressIntern.street"
        :value="addressIntern.street"
        :disabled="disabled"
        :items="groupBy(streetItems, 'street')"
        :menu-props="{ maxHeight: '400' }"
        label="Straße *"
        hint="Straße auswählen"
        persistent-placeholder
        dense
        hide-no-data
      />
      <v-text-field
        v-model="addressIntern.houseNumber"
        label="Hausnummer *"
        persistent-placeholder
        dense
        :disabled="disabled"
        :error-messages="validationErrors.houseNumber._validationErrors"
        @input="
          validationErrors.houseNumber._validationErrors =
            validateHouseNumber($event)
        "
      />

      <v-text-field
        v-model="addressIntern.houseNumberSupplement"
        label="Hausnummernzusatz"
        :disabled="disabled"
        persistent-placeholder
        dense
        :counter="20"
        maxlength="20"
      />
    </div>
    <div v-else class="input-row margin-top-1">
      <v-autocomplete
        v-model="addressIntern.street"
        :value="addressIntern.street"
        :disabled="disabled"
        :items="groupBy(streetItems, 'street')"
        :menu-props="{ maxHeight: '400' }"
        label="Straße *"
        hint="Straße auswählen"
        persistent-placeholder
        dense
        hide-no-data
      />
      <v-text-field
        v-model="addressIntern.alternativeLocationDescription"
        label="Standortbeschreibung *"
        persistent-placeholder
        dense
        :disabled="disabled"
      />
    </div>
  </div>
</template>

<script>
import debounce from 'debounce';
import _ from 'lodash';
import { HTTP } from '@/main/httpClient.js';

export default {
  name: 'AddressAutocomplete',
  components: {},
  props: {
    previouslySelectedAddress: {
      type: Object,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    alternativeLocationDescriptionIsRequired: {
      type: Boolean,
      required: false,
      default: false
    },
    isInstallationAddress: {
      type: Boolean,
      required: false,
      // we now have Infas360 data for all of germany, so that case should be true for all addresses now
      default: true
    }
  },
  data: () => ({
    validationErrors: null,
    cityItems: [],
    streetItems: [],
    zipItems: [],
    loading: false,
    search: '',
    addressIntern: {
      zipCode: '',
      city: '',
      street: '',
      houseNumber: '',
      geo: '',
    }
  }),
  watch: {
    search(value) {
      if (!value) {
        return;
      }
      if (value.length >= 3) {
        debounce(this.makeSearch, 900)(value, this);
      }
    },
    previouslySelectedAddress: {
      deep: true,
      handler(newVal, oldVal) {
        if (_.isEqual(newVal, oldVal) === false) {
          this.addressIntern = _.cloneDeep(newVal);
          this.validationErrors = this.buildInitialValidationErrors(
            this.addressIntern
          );
        }
      }
    },
    'addressIntern.zipCode'(value) {
      if (!value) {
        return;
      }
      if (value.length >= 3) {
        this.getCities(value);
      }
    },
    'addressIntern.city'(value) {
      if (!value) {
        return;
      }
      if (value.length >= 3 && this.addressIntern.zipCode?.length === 5) {
        this.getStreets(this.addressIntern.zipCode, value);
      }
    },
    'addressIntern.geo'(newVal) {
      if (!newVal) {
        return;
      }
      if (newVal && newVal.length >= 3) {
        // this.$emit('update', {
        //   value: {
        //     zipCode: this.addressIntern.zipCode,
        //     city: this.addressIntern.city,
        //     street: this.addressIntern.stree,
        //     houseNumber: this.addressIntern.houseNumber,
        //     geo: newVal
        //   },
        //   validationErrors: this.validationErrors
        // });
        this.$emit('updategeo', this.addressIntern.geo);
      }
    },
    addressIntern: {
      deep: true,
      handler(newVal) {
        this.validationErrors._validationErrors =
          this.checkForMissingFields(newVal);
        // newVal.alternativeLocationDescriptionIsSelected =
        //   this.alternativeLocationDescriptionIsRequired;
        this.$emit('update', {
          value: newVal,
          validationErrors: this.validationErrors
        });
      }
    }
  },
  created() {
    if (this.previouslySelectedAddress) {
      this.addressIntern = _.cloneDeep(this.previouslySelectedAddress);
    } else {
      this.addressIntern = this.buildDefaultAddress();
    }
    this.validationErrors = this.buildInitialValidationErrors(
      this.addressIntern
    );
  },
  methods: {
    buildDefaultAddress() {
      return {
        alternativeLocationDescriptionIsSelected:
          this.alternativeLocationDescriptionIsRequired,
        zipCode: null,
        city: null,
        street: null,
        houseNumber: null,
        houseNumberSupplement: null,
        alternativeLocationDescription: null
      };
    },
    buildInitialValidationErrors(address) {
      return {
        _validationErrors: this.checkForMissingFields(address),
        zipCode: {
          _validationErrors: this.validateZipCode(address.zipCode)
        },
        city: { _validationErrors: [] },
        street: { _validationErrors: [] },
        houseNumber: {
          _validationErrors: this.validateHouseNumber(address.houseNumber)
        },
        houseNumberSupplement: { _validationErrors: [] },
        alternativeLocationDescription: { _validationErrors: [] }
      };
    },
    checkForMissingFields(address) {
      const errorMessages = [];
      if (!address.zipCode) {
        errorMessages.push('Es muss ein Postleitzahl angegeben werden.');
      }
      if (!address.city) {
        errorMessages.push('Es muss ein Stadt angegeben werden.');
      }
      if (
        !this.alternativeLocationDescriptionIsRequired &&
        !address.houseNumber
      ) {
        errorMessages.push('Es muss eine Hausnummer angegeben werden.');
      }
      if (
        this.alternativeLocationDescriptionIsRequired === true &&
        !address.alternativeLocationDescription
      ) {
        errorMessages.push(
          'Es muss eine Standortbeschreibung angegeben werden.'
        );
      }
      return errorMessages;
    },
    async makeSearch(value, self) {
      if (value.length === 5) {
        this.addressIntern.zipCode = value;
        this.getCities(value);
      } else if (value.length >= 2) {
        if (!value) {
          self.zipItems = [];
          self.zipCode = '';
        }
        if (self.loading) {
          return;
        }

        self.loading = true;
        if (this.isInstallationAddress) {
          HTTP.get('/past/geo/property-address?zip=' + value)
            .then((response) => {
              self.zipItems = response.data.possibleZipCodes;
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => (self.loading = false));
        } else {
          HTTP.get(
            '/past/postcodeDatasets?where[zip][startsWith]=' +
              value +
              '&distinct=city&[take]=100'
          )
            .then((response) => {
              self.zipItems = response.data;
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => (self.loading = false));
        }
      }
    },
    getCities(zip) {
      this.loading = true;
      if (this.isInstallationAddress) {
        HTTP.get('/past/geo/property-address?zip=' + zip)
          .then((response) => {
            this.cityItems = response.data.cities;
            if (
              this.groupBy(response.data.cities).length === 1 &&
              !this.addressIntern.city
            ) {
              this.addressIntern.city = response.data.cities[0];
            }
          })
          .catch((error) => console.log(error))
          .finally(() => (this.loading = false));
      } else {
        HTTP.get(
          '/past/postcodeDatasets?where[zip][equals]=' +
            zip +
            '&distinct=city&[take]=25'
        )
          .then((response) => {
            this.cityItems = response.data;
            if (
              this.groupBy(response.data).length === 1 &&
              !this.addressIntern.city
            ) {
              this.addressIntern.city = response.data[0].city;
            }
          })
          .catch((error) => console.log(error))
          .finally(() => (this.loading = false));
      }
    },
    getStreets(zip, city) {
      this.loading = true;
      if (this.isInstallationAddress) {
        HTTP.get('/past/geo/property-address?city=' + city + '&zip=' + zip)
          .then((response) => {
            this.streetItems = response.data.streets;
            if (response.data && response.data.geojson3857 && response.data.geojson3857.length) {
              this.addressIntern.geo = response.data.geojson3857;
              this.$emit('updategeo', response.data.geojson3857);
            }
          })
          .catch((error) => console.log(error))
          .finally(() => (this.loading = false));
      } else {
        HTTP.get(
          '/past/postcodeDatasets?where[zip][equals]=' +
            zip +
            '&where[city][equals]=' +
            city +
            '&distinct=street&[take]=500'
        )
          .then((response) => (this.streetItems = response.data))
          .catch((error) => console.log(error))
          .finally(() => (this.loading = false));
      }
    },
    groupBy(items, key) {
      if (this.isInstallationAddress) {
        // The API returns only the relevant data which doesn't need to be grouped in this case.
        return items;
      } else {
        if (items.length) {
          const cGroup = _.groupBy(items, key);
          const cArray = [];
          let cName;
          Object.keys(cGroup).forEach((item) => {
            cName = item.length ? item : cGroup[item][0].key;
            cArray.push(cName);
          });
          return cArray.sort();
        } else {
          return [];
        }
      }
    },
    clearAddress() {
      this.addressIntern = {
        zipCode: '',
        city: '',
        street: '',
        houseNumber: '',
        geo: '',
      };
    },
    validateZipCode: (v) => {
      if (
        !!v &&
        !(v && v.length <= 5 && v.length >= 4 && /^\d{4,5}$/.test(v))
      ) {
        return ['Die Postleitzahl ist ungültig.'];
      }
      return [];
    },
    validateHouseNumber(houseNumber) {
      if (
        houseNumber === null ||
        houseNumber === undefined ||
        houseNumber === '' ||
        /^\d{1,5}$/.test(houseNumber)
      ) {
        return [];
      } else {
        return ['Die Hausnummer enthält ungültige Zeichen.'];
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.input-row-adress-data {
  display: grid;
  grid-template-columns: auto max-content max-content;
  grid-gap: var(--goe-spacing-1);
}
</style>
