<template>
  <div>
    <div class="margin-bottom-0_5">
      <DateDisplay :date="orderDate" label="Datum der Unterschrift" />
    </div>
    <ValidationErrorDisplay
      v-if="hasErrors(validationErrorsOrderDate) === true"
      :validatitationErrors="validationErrorsOrderDate"
    />
  </div>
</template>

<script>
import DisplayContainer from '@/components/elements/DisplayContainer.vue';

import DateDisplay from '@/components/dataDisplays/DateDisplay.vue';
import ValidationErrorDisplay from '../ValidationErrorDisplay.vue';
import { hasErrors } from '../ValidationError.js';

export default {
  name: 'CustomerDataSummary',
  components: {
    DisplayContainer,
    DateDisplay,
    ValidationErrorDisplay
  },
  props: {
    orderDate: {
      type: String,
      required: false,
      default: null
    },
    validationErrorsOrderDate: {
      type: Object,
      required: true
    }
  },
  methods: {
    hasErrors(validationErrors) {
      return hasErrors(validationErrors);
    }
  }
};
</script>

<style></style>
