var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.hasErrors(_vm.validationErrors) && _vm.displayError)?_c('ValidationErrorDisplay',{attrs:{"validatitationErrors":_vm.validationErrors}}):_vm._e(),(_vm.customerInformation.salutation !== 'COMPANY')?_c('div',[_c('div',{staticClass:"salutation-with-name-wrapper"},[_c('v-select',{attrs:{"label":"Anrede * ","persistent-placeholder":"","items":_vm.salutationData,"item-text":function (item) { return item.label; },"item-value":"data","dense":"","disabled":_vm.disabled,"hide-details":""},on:{"change":function($event){if (_vm.customerInformation.salutation !== 'COMPANY') {
            _vm.customerInformation.company = null;
            _vm.validationErrors.company._validationErrors = [];
          }
          _vm.emitCurrentState();}},model:{value:(_vm.customerInformation.salutation),callback:function ($$v) {_vm.$set(_vm.customerInformation, "salutation", $$v)},expression:"customerInformation.salutation"}}),_c('v-text-field',{attrs:{"label":"Vorname *","error-messages":_vm.validationErrors.firstName._validationErrors,"dense":"","disabled":_vm.disabled,"persistent-placeholder":""},on:{"input":function($event){{
            _vm.validationErrors.firstName._validationErrors =
              _vm.validateFirstName($event);
            _vm.emitCurrentState();
          }}},model:{value:(_vm.customerInformation.firstName),callback:function ($$v) {_vm.$set(_vm.customerInformation, "firstName", $$v)},expression:"customerInformation.firstName"}}),_c('v-text-field',{attrs:{"label":"Nachname *","error-messages":_vm.validationErrors.lastName._validationErrors,"dense":"","disabled":_vm.disabled,"persistent-placeholder":""},on:{"input":function($event){{
            _vm.validationErrors.lastName._validationErrors =
              _vm.validateLastName($event);
            _vm.emitCurrentState();
          }}},model:{value:(_vm.customerInformation.lastName),callback:function ($$v) {_vm.$set(_vm.customerInformation, "lastName", $$v)},expression:"customerInformation.lastName"}})],1),_c('div',{staticClass:"input-row margin-bottom-1"},[(!_vm.displayBasicInformation)?_c('DatePicker',{attrs:{"label":"Geburtsdatum","placeholder":"","disabled":_vm.disabled,"maxDate":_vm.latestAllowedBirthDay,"dense":true},on:{"input":function($event){{
            _vm.emitCurrentState();
          }}},model:{value:(_vm.customerInformation.birthDate),callback:function ($$v) {_vm.$set(_vm.customerInformation, "birthDate", $$v)},expression:"customerInformation.birthDate"}}):_vm._e(),(!_vm.displayBasicInformation)?_c('v-text-field',{attrs:{"label":"E-Mail","error-messages":_vm.validationErrors.email._validationErrors,"dense":"","disabled":_vm.disabled,"persistent-placeholder":""},on:{"input":function($event){{
            _vm.validationErrors.email._validationErrors = _vm.validateEmail($event);
            _vm.emitCurrentState();
          }}},model:{value:(_vm.customerInformation.email),callback:function ($$v) {_vm.$set(_vm.customerInformation, "email", $$v)},expression:"customerInformation.email"}}):_vm._e()],1),_c('div',{staticClass:"input-row"},[(!_vm.displayBasicInformation)?_c('v-text-field',{attrs:{"id":"mobileNumber","error-messages":_vm.validationErrors.cellPhoneNumber._validationErrors,"label":"Mobilnummer","dense":"","disabled":_vm.disabled,"persistent-placeholder":""},on:{"input":function($event){{
            _vm.validationErrors.cellPhoneNumber._validationErrors =
              _vm.validateMobileNumber($event);
            _vm.emitCurrentState();
          }}},model:{value:(_vm.customerInformation.cellPhoneNumber),callback:function ($$v) {_vm.$set(_vm.customerInformation, "cellPhoneNumber", $$v)},expression:"customerInformation.cellPhoneNumber"}}):_vm._e(),(!_vm.displayBasicInformation)?_c('v-text-field',{attrs:{"label":"Telefonnummer","error-messages":_vm.validationErrors.phone._validationErrors,"dense":"","disabled":_vm.disabled,"persistent-placeholder":""},on:{"input":function($event){{
            _vm.validationErrors.phone._validationErrors =
              _vm.validatePhoneNumber($event);
            _vm.emitCurrentState();
          }}},model:{value:(_vm.customerInformation.phone),callback:function ($$v) {_vm.$set(_vm.customerInformation, "phone", $$v)},expression:"customerInformation.phone"}}):_vm._e()],1)]):_c('div',[_c('div',{staticClass:"salutation-with-company-wrapper"},[_c('v-select',{attrs:{"items":_vm.salutationData,"item-text":function (item) { return item.label; },"item-value":"data","label":"Anrede","dense":"","persistent-placeholder":"","disabled":_vm.disabled,"hide-details":""},on:{"change":function($event){if (_vm.customerInformation.salutation !== 'COMPANY') {
            _vm.customerInformation.company = null;
            _vm.validationErrors.company._validationErrors = [];
          }
          _vm.emitCurrentState();}},model:{value:(_vm.customerInformation.salutation),callback:function ($$v) {_vm.$set(_vm.customerInformation, "salutation", $$v)},expression:"customerInformation.salutation"}}),_c('v-text-field',{attrs:{"dense":"","label":"Firma *","persistent-placeholder":"","disabled":_vm.disabled,"error-messages":_vm.validationErrors.company._validationErrors},on:{"input":function($event){{
            _vm.validationErrors.company._validationErrors =
              _vm.validateCompanyName($event);
            _vm.emitCurrentState();
          }}},model:{value:(_vm.customerInformation.company),callback:function ($$v) {_vm.$set(_vm.customerInformation, "company", $$v)},expression:"customerInformation.company"}})],1),_c('div',{staticClass:"input-row margin-bottom-1"},[_c('v-text-field',{attrs:{"label":"Vorname Ansprechpartner","error-messages":_vm.validationErrors.firstName._validationErrors,"dense":"","disabled":_vm.disabled,"persistent-placeholder":""},on:{"input":function($event){{
            _vm.validationErrors.firstName._validationErrors =
              _vm.validateFirstName($event);
            _vm.emitCurrentState();
          }}},model:{value:(_vm.customerInformation.firstName),callback:function ($$v) {_vm.$set(_vm.customerInformation, "firstName", $$v)},expression:"customerInformation.firstName"}}),_c('v-text-field',{attrs:{"label":"Nachname Ansprechpartner","error-messages":_vm.validationErrors.lastName._validationErrors,"dense":"","disabled":_vm.disabled,"persistent-placeholder":""},on:{"input":function($event){{
            _vm.validationErrors.lastName._validationErrors =
              _vm.validateLastName($event);
            _vm.emitCurrentState();
          }}},model:{value:(_vm.customerInformation.lastName),callback:function ($$v) {_vm.$set(_vm.customerInformation, "lastName", $$v)},expression:"customerInformation.lastName"}})],1),_c('div',{staticClass:"input-row"},[(!_vm.displayBasicInformation)?_c('v-text-field',{attrs:{"id":"mobileNumber","dense":"","label":"Mobilnummer","error-messages":_vm.validationErrors.cellPhoneNumber._validationErrors,"disabled":_vm.disabled,"persistent-placeholder":""},on:{"input":function($event){{
            _vm.validationErrors.cellPhoneNumber._validationErrors =
              _vm.validateMobileNumber($event);
            _vm.emitCurrentState();
          }}},model:{value:(_vm.customerInformation.cellPhoneNumber),callback:function ($$v) {_vm.$set(_vm.customerInformation, "cellPhoneNumber", $$v)},expression:"customerInformation.cellPhoneNumber"}}):_vm._e(),(!_vm.displayBasicInformation)?_c('v-text-field',{attrs:{"dense":"","disabled":_vm.disabled,"label":"Telefonnummer","error-messages":_vm.validationErrors.phone._validationErrors,"persistent-placeholder":""},on:{"input":function($event){{
            _vm.validationErrors.phone._validationErrors =
              _vm.validatePhoneNumber($event);
            _vm.emitCurrentState();
          }}},model:{value:(_vm.customerInformation.phone),callback:function ($$v) {_vm.$set(_vm.customerInformation, "phone", $$v)},expression:"customerInformation.phone"}}):_vm._e()],1),(!_vm.displayBasicInformation)?_c('v-text-field',{attrs:{"dense":"","label":"E-Mail","persistent-placeholder":"","disabled":_vm.disabled,"error-messages":_vm.validationErrors.email._validationErrors},on:{"input":function($event){{
          _vm.validationErrors.email._validationErrors = _vm.validateEmail($event);
          _vm.emitCurrentState();
        }}},model:{value:(_vm.customerInformation.email),callback:function ($$v) {_vm.$set(_vm.customerInformation, "email", $$v)},expression:"customerInformation.email"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }