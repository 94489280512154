<template>
  <div>
    <div class="input-row margin-bottom-0_5">
      <DataDisplay label="Produkt Baukostenzuschuss">
        <div v-if="selectedHouseConnectionOrderProduct">
          {{ selectedHouseConnectionOrderProduct.name }}
        </div>
      </DataDisplay>
    </div>
    <ValidationErrorDisplay
      v-if="
        hasErrors(validationErrorsSelectedHouseConnectionOrderProduct) === true
      "
      :validatitationErrors="
        validationErrorsSelectedHouseConnectionOrderProduct
      "
    />
  </div>
</template>

<script>
import lodash from 'lodash';

import OrderProductTable from '../productSelection/OrderProductTable.vue';
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import DataDisplay from '@/components/elements/DataDisplay.vue';
import NormalButton from '@/components/elements/NormalButton.vue';
import Loader from '@/components/Loader.vue';
import DatePicker from '@/components/elements/DatePicker.vue';
import ValidationErrorDisplay from '../ValidationErrorDisplay.vue';
import { hasErrors } from '../ValidationError.js';
export default {
  name: 'ServiceOrderProductSummary',
  components: {
    DisplayContainer,
    DataDisplay,
    NormalButton,
    Loader,
    DatePicker,
    OrderProductTable,
    ValidationErrorDisplay
  },
  props: {
    selectedHouseConnectionOrderProduct: {
      type: Object,
      required: false,
      default: null
    },
    validationErrorsSelectedHouseConnectionOrderProduct: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    selectedProducts: null
  }),
  created() {
    if (this.previouslySelectedProducts) {
      this.selectedProducts = lodash.cloneDeep(this.previouslySelectedProducts);
    }
  },
  methods: {
    hasErrors(validationErrors) {
      return hasErrors(validationErrors);
    }
  }
};
</script>

<style lang="scss" scoped></style>
