<template>
  <div>
    <ValidationErrorDisplay
      v-if="hasErrors(collectedValidationErrors) && displayError"
      :validatitationErrors="collectedValidationErrors"
    />
    <ValidationErrorDisplay
      v-if="
        (hasErrors(validationErrorsFromBackend) ||
          hasErrors(validationErrorsForAgency)) &&
          !displayError
      "
      :validatitationErrors="
        mergeValidationErrors(
          validationErrorsFromBackend,
          validationErrorsForAgency
        )
      "
    />
    <DataDisplay label="Liegenschaftsstatus">
      <div v-if="propertyStatus">
        <v-tooltip top :disabled="!propertyStatus.projectId">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="
                propertyStatus.status === 'home-connected' ||
                  propertyStatus.status === 'premises-connected' ||
                  propertyStatus.status === 'home-passed'
              "
              v-bind="attrs"
              v-on="on"
            >
              mdi-home-assistant
            </v-icon>
            <v-icon
              v-else-if="
                propertyStatus.status === 'project-planned' ||
                  propertyStatus.status === 'project-started'
              "
              v-bind="attrs"
              v-on="on"
            >
              mdi-home-planed
            </v-icon>
            <v-icon
              v-else
              v-bind="attrs"
              v-on="on"
            >
              mdi-home-remove
            </v-icon>
          </template>
          <span>
            {{ propertyStatus.projectId }}
          </span>
        </v-tooltip>
        {{ propertyStatus.status }}
      </div>
    </DataDisplay>
    <v-tabs
      v-model="selectedTabIndex"
      class="v-tabs margin-top-1"
      :background-color="'var(--goe-background-highlight)'"
    >
      <v-tabs-slider color="primary"></v-tabs-slider>
      <v-tab
        v-for="item in tabOptions"
        :key="item.goetelShipsDevices"
        :disabled="disabled || backendCallActive || item.disabled"
      >
        {{ item.title }}
      </v-tab>
    </v-tabs>
    <v-tabs-items
      v-model="selectedTabIndex"
      class="v-tabs-items-color margin-top-1"
      style="background-color: var(--goe-background-highlight)"
      :background-color="'var(--goe-background-highlight)'"
    >
      <v-tab-item eager class="margin-top-1">
        <AddressAutocomplete
          :previouslySelectedAddress="installationAddress"
          :disabled="disabled"
          :isInstallationAddress="true"
          @update="
            {
              updateCurrentState($event);
              validationErrorsAddressWithHouseNumber = $event.validationErrors;
              if (
                tabOptions[selectedTabIndex]
                  .alternativeLocationDescriptionIsRequired === false
              ) {
                validateInstallationAddressWithHouseNumber();
                updatePropertyStatus();
              }
            }
          "
        />
      </v-tab-item>
      <v-tab-item eager class="margin-top-1">
        <AddressAutocomplete
          :previouslySelectedAddress="installationAddress"
          :disabled="disabled"
          :alternativeLocationDescriptionIsRequired="true"
          :isInstallationAddress="true"
          @update="
            {
              updateCurrentState($event);
              validationErrorsAddressWithAlternativeDesciption =
                $event.validationErrors;
              if (
                tabOptions[selectedTabIndex]
                  .alternativeLocationDescriptionIsRequired === true
              ) {
                validateInstallationAddressWithAlternativeDesciption();
                updatePropertyStatus();
              }
            }
          "
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import AddressAutocomplete from './AddressAutocomplete.vue';
import DataDisplay from '@/components/elements/DataDisplay.vue';
import lodash from 'lodash';
import { HTTP } from '@/main/httpClient.js';

import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import ValidationErrorDisplay from '../ValidationErrorDisplay.vue';

import { hasErrors, mergeValidationErrors } from '../ValidationError.js';

import {
  customerInformationIsValidForBackendCall,
  buildAddressDto,
  addressIsAvailable,
  buildValidationMessagesFromUserValidation
} from './AddressValidationUtil.js';

const locationsAllowedForAgencies = {
  37127: ['Dransfeld'],
  34385: ['Bad Karlshafen', 'Helmarshausen'],
  37194: ['Bodenfelde', 'Wahmbeck'],
  34593: ['Knüllwald'],
  31868: ['Ottenstein']
};
export default {
  name: 'InstallationAddressSelection',
  components: {
    DisplayContainer,
    AddressAutocomplete,
    ValidationErrorDisplay,
    DataDisplay
  },
  props: {
    previouslySelectedInstallationAddress: {
      type: Object,
      required: false,
      default: null
    },
    installationAddressIsServiceOrderInvoiceAddress: {
      type: Boolean,
      required: false,
      default: null
    },
    installationAddressIsHouseConnectionOrderInvoiceAddress: {
      type: Boolean,
      required: false,
      default: null
    },
    customerInformation: {
      type: Object,
      required: false,
      default: null
    },
    validationErrorsCustomerInformation: {
      type: Object,
      required: false,
      default: null
    },
    orderType: {
      // Enum indicating order type -> SERVICE || HOUSECONNECTION || HOUSECONNECTION_WITH_SERVICE
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    displayError: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    installationAddress: null,
    backendValidationParameters: {},
    collectedValidationErrors: null,
    validationErrorsForAgency: null,
    validationErrorsFromBackend: null, // are related to validationErrorsAddressWithHouseNumber
    validationErrorsAddressWithHouseNumber: null,
    validationErrorsAddressWithAlternativeDesciption: null,
    propertyStatus: null,
    propertyStatusParameters: null,
    backendCallActive: false,
    tabOptions: [
      {
        alternativeLocationDescriptionIsRequired: false,
        title: 'Hausnummer bekannt',
        disabled: false
      },
      {
        alternativeLocationDescriptionIsRequired: true,
        title: 'Hausnummer unbekannt',
        disabled: true
      }
    ],
    selectedTabIndex: 0
  }),
  computed: {
    alternativeLocationDescriptionIsSelected: function () {
      return this.tabOptions[this.selectedTabIndex]
        .alternativeLocationDescriptionIsRequired;
    }
  },
  watch: {
    alternativeLocationDescriptionIsSelected: {
      handler: function (newValue, _) {
        this.validateInstallationAddress();
        this.updatePropertyStatus();
      }
    },
    'customerInformation.salutation': {
      handler: function (newValue, _) {
        if (newValue) {
          this.validateInstallationAddress();
        }
      }
    },
    'customerInformation.firstName': {
      handler: function (newValue, _) {
        if (newValue) {
          this.validateInstallationAddress();
        }
      }
    },
    'customerInformation.lastName': {
      handler: function (newValue, _) {
        if (newValue) {
          this.validateInstallationAddress();
        }
      }
    },
    'customerInformation.company': {
      handler: function (newValue, _) {
        if (newValue) {
          this.validateInstallationAddress();
        }
      }
    },
    installationAddressIsHouseConnectionOrderInvoiceAddress: {
      handler: function (newValue, _) {
        if (newValue === true || newValue === false) {
          this.validateInstallationAddress();
        }
      }
    },
    installationAddressIsServiceOrderInvoiceAddress: {
      handler: function (newValue, _) {
        if (newValue === true || newValue === false) {
          this.validateInstallationAddress();
        }
      }
    }
  },
  created() {
    if (this.$props.previouslySelectedInstallationAddress) {
      this.installationAddress = lodash.cloneDeep(
        this.$props.previouslySelectedInstallationAddress
      );
    } else {
      this.installationAddress = null;
    }
    if (
      this.$props.previouslySelectedInstallationAddress
        ?.alternativeLocationDescriptionIsSelected === true
    ) {
      this.selectedTabIndex = 1;
    } else {
      this.selectedTabIndex = 0;
    }
    this.collectedValidationErrors = this.buildInitialValidationErrors();
    this.validationErrorsFromBackend = this.buildInitialValidationErrors();
    this.validationErrorsForAgency = this.buildInitialValidationErrors();
    this.validationErrorsAddressWithAlternativeDesciption =
      this.buildInitialValidationErrors();
    this.validateInstallationAddress();
    this.updatePropertyStatus();
  },
  methods: {
    async updatePropertyStatus() {
      if (this.alternativeLocationDescriptionIsSelected === true) {
        this.propertyStatus = null;
      } else {
        await this.checkPropertyStatus(
          this.installationAddress,
          this.validationErrorsAddressWithHouseNumber
        );
      }
    },
    hasErrors(validationErrors) {
      return hasErrors(validationErrors);
    },
    checkPropertyStatus: lodash.debounce(
      async function (installationAddress, validationErrorsAddressComponent) {
        let newBackendValidationParameters = null;
        if (
          this.installationAddress &&
          !hasErrors(validationErrorsAddressComponent)
        ) {
          newBackendValidationParameters =
            lodash.cloneDeep(installationAddress);
        }

        if (
          newBackendValidationParameters &&
          !hasErrors(validationErrorsAddressComponent)
        ) {
          this.propertyStatusParameters = newBackendValidationParameters;

          HTTP.get('/order/propertyStatus', {
            params: {
              zipCode: installationAddress.zipCode,
              city: installationAddress.city,
              street: installationAddress.street,
              houseNumber: installationAddress.houseNumber,
              houseNumberSupplement: installationAddress.houseNumberSupplement
            }
          })
            .then((response) => (this.propertyStatus = response.data))
            .catch(() => (this.propertyStatus = null));
        }
      },
      900,
      { leading: true }
    ),

    updateCurrentState($event) {
      this.installationAddress = lodash.cloneDeep($event.value);
    },
    validateInstallationAddress() {
      if (this.alternativeLocationDescriptionIsSelected) {
        this.validateInstallationAddressWithAlternativeDesciption();
      } else {
        this.validateInstallationAddressWithHouseNumber();
      }
    },
    async validateInstallationAddressWithHouseNumber() {
      this.validationErrorsForAgency = this.checkLocationForAgency(
        this.installationAddress
      );
      await this.validateInstallationAddressByBackendCall(
        this.installationAddress,
        this.validationErrorsAddressWithHouseNumber
      );
      this.collectedValidationErrors = mergeValidationErrors(
        this.validationErrorsFromBackend,
        mergeValidationErrors(
          this.validationErrorsForAgency,
          this.validationErrorsAddressWithHouseNumber
        )
      );

      this.emitCurrentState();
    },
    validateInstallationAddressWithAlternativeDesciption() {
      this.validationErrorsForAgency = this.checkLocationForAgency(
        this.installationAddress
      );
      this.validationErrorsFromBackend = this.buildInitialValidationErrors();
      this.collectedValidationErrors = mergeValidationErrors(
        this.validationErrorsForAgency,
        this.validationErrorsAddressWithAlternativeDesciption
      );
      this.emitCurrentState();
    },
    validateInstallationAddressByBackendCall: lodash.debounce(
      async function (installationAddress, validationErrorsAddressComponent) {
        let newBackendValidationParameters = null;
        if (this.customerInformation && this.installationAddress) {
          newBackendValidationParameters = this.buildBackendCallParameters(
            this.installationAddressIsServiceOrderInvoiceAddress,
            this.installationAddressIsHouseConnectionOrderInvoiceAddress,
            lodash.cloneDeep(
              buildAddressDto(this.customerInformation, installationAddress)
            )
          );
        }

        if (
          newBackendValidationParameters &&
          customerInformationIsValidForBackendCall(
            this.customerInformation,
            this.validationErrorsCustomerInformation
          ) &&
          !hasErrors(validationErrorsAddressComponent)
        ) {
          this.validationErrorsFromBackend = { _validationErrors: [] };
          await this.performBackendValidation(installationAddress);
        }
      },
      900,
      { leading: true }
    ),
    checkLocationForAgency(installationAddress) {
      if (
        !this.locationIsAllowedForCurrentUser(
          installationAddress?.zipCode,
          installationAddress?.city
        )
      ) {
        return {
          _validationErrors: [
            'Eine Addresseingabe in diesem Postleitzahlengebiet ist unzulässig.'
          ]
        };
      } else {
        return this.buildInitialValidationErrors();
      }
    },
    async performBackendValidation(installationAddress) {
      const originalInstallationAddress = lodash.cloneDeep(
        buildAddressDto(this.customerInformation, installationAddress)
      );
      const backendCalls = [];
      this.backendCallActive = true;

      backendCalls.push(
        this.checkIfContractAtInstallationAddressAlreadyExists(
          originalInstallationAddress
        )
      );
      if (
        this.installationAddressIsServiceOrderInvoiceAddress ||
        this.installationAddressIsHouseConnectionOrderInvoiceAddress
      ) {
        backendCalls.push(
          this.checkIfCustomerAlreadyExists(originalInstallationAddress)
        );
      }
      const currentBackendValidationParameters =
        this.buildBackendCallParameters(
          this.installationAddressIsServiceOrderInvoiceAddress,
          this.installationAddressIsHouseConnectionOrderInvoiceAddress,
          originalInstallationAddress
        );
      this.backendValidationParameters = currentBackendValidationParameters;
      await Promise.allSettled(backendCalls)
        .then((results) => {
          if (
            lodash.isEqual(
              // Ignore result if address changed since the method call
              currentBackendValidationParameters,
              this.backendValidationParameters
            )
          ) {
            const displayErrors = results
              .filter((promiseResult) => promiseResult.status === 'fulfilled')
              .flatMap((promiseResult) => promiseResult.value);

            this.validationErrorsFromBackend = {
              _validationErrors: displayErrors
            };
          }
        })
        .finally(() => {
          this.backendCallActive = false;
          this.emitCurrentState();
        });
    },
    buildBackendCallParameters(
      installationAddressIsServiceOrderInvoiceAddress,
      installationAddressIsHouseConnectionOrderInvoiceAddress,
      addressDto
    ) {
      return {
        installationAddressIsServiceOrderInvoiceAddress:
          installationAddressIsServiceOrderInvoiceAddress,
        installationAddressIsHouseConnectionOrderInvoiceAddress:
          installationAddressIsHouseConnectionOrderInvoiceAddress,
        addressDto: addressDto
      };
    },
    checkIfContractAtInstallationAddressAlreadyExists: function (
      originalInstallationAddress
    ) {
      return addressIsAvailable(
        this.orderType,
        'INSTALLATION',
        originalInstallationAddress
      ).then((result) => {
        return this.buildValidationMessagesFromInstallationValidation(result);
      });
    },
    checkIfCustomerAlreadyExists: function (originalInstallationAddress) {
      let orderType = null;
      if (
        this.installationAddressIsServiceOrderInvoiceAddress &&
        this.installationAddressIsHouseConnectionOrderInvoiceAddress
      ) {
        orderType = 'HOUSECONNECTION_WITH_SERVICE';
      } else if (this.installationAddressIsServiceOrderInvoiceAddress) {
        orderType = 'SERVICE';
      } else {
        orderType = 'HOUSECONNECTION';
      }
      return addressIsAvailable(
        orderType,
        'INVOICE',
        originalInstallationAddress
      ).then((result) => {
        return buildValidationMessagesFromUserValidation(orderType, result);
      });
    },
    locationIsAllowedForCurrentUser(zipCode, city) {
      if (this.currentUserIsInAgency()) {
        return (
          !zipCode ||
          (zipCode && zipCode.length < 5) ||
          (zipCode in locationsAllowedForAgencies &&
            locationsAllowedForAgencies[zipCode].filter(
              (allowedCity) => allowedCity === city
            ).length > 0)
        );
      } else {
        return true;
      }
    },
    currentUserIsInAgency() {
      return this.$auth.check('LgSpotAgentur');
    },

    buildValidationMessagesFromInstallationValidation(error) {
      if (!error || error.response.status !== 400) {
        return [];
      } else {
        const collectedErrors = [];
        error.response.data.forEach((validationError) => {
          if (
            validationError.code ===
            'ORDER_HOUSE_CONNECTION_IS_MISSING_AT_ADDRESS'
          ) {
            collectedErrors.push(
              'An dieser Adresse gibt es noch keinen Hausanschlussauftrag.'
            );
          } else if (
            validationError.code === 'ORDER_IS_ALREADY_PLACED_AT_ADDRESS'
          ) {
            const contractType = validationError.payload.contractType;
            const contractId = validationError.payload.contractId;
            if (contractType === 'SERVICE_ORDER') {
              collectedErrors.push(
                `An dieser Adresse gibt es bereits den Diensteauftrag mit der Auftragsnummer ${contractId}.`
              );
            } else {
              collectedErrors.push(
                `An dieser Adresse gibt es bereits den Hausanschlussauftrag mit der Auftragsnummer ${contractId}.`
              );
            }
          }
        });
        return collectedErrors;
      }
    },
    emitCurrentState() {
      if (this.installationAddress) {
        this.installationAddress.alternativeLocationDescriptionIsSelected =
          this.alternativeLocationDescriptionIsSelected;
      }

      this.$emit('installationAddressChanged', {
        value: this.installationAddress,
        validationErrors: this.collectedValidationErrors,
        validationOngoing: this.backendCallActive
      });
    },
    buildInitialValidationErrors() {
      return { _validationErrors: [] };
    },
    mergeValidationErrors(val1, val2) {
      return mergeValidationErrors(val1, val2);
    }
  }
};
</script>

<style lang="scss" scoped>
.v-tabs ::v-deep .v-tabs-bar {
  border-radius: inherit;
  height: 24px;
}
.align-elements {
  display: flex;
  align-items: center;
}
</style>
