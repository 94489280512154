var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"v-radio-group margin-bottom-1"},[_c('v-radio-group',{attrs:{"value":_vm.portingOptions.portPhoneNumbersWithCancellation,"label":"WBCI-Prozess-Typ","dense":"","hide-details":""},on:{"change":function($event){_vm.portingOptions.portPhoneNumbersWithCancellation = $event;
        _vm.emitCurrentState();}}},[_c('v-radio',{attrs:{"value":true,"label":"Rufnummerportierung mit Kündigung (KUE-MRN)"}}),_c('v-radio',{attrs:{"value":false,"label":"Reine Rufnummernportierung (RRNP)"}})],1)],1),_c('div',{staticClass:"v-radio-group margin-bottom-1"},[_c('v-radio-group',{attrs:{"value":_vm.portingOptions.portAllPhoneNumbers,"label":"Alle Rufnummern portieren?","dense":"","hide-details":""},on:{"change":function($event){_vm.portingOptions.portAllPhoneNumbers = $event;
        _vm.emitCurrentState();}}},[_c('v-radio',{attrs:{"value":true,"label":"Alle Rufnummern"}}),_c('v-radio',{attrs:{"value":false,"label":"Nur ausgewählte Rufnummern"}})],1)],1),_c('div',{staticClass:"input-row margin-bottom-1"},[_c('NumberManagementService',{staticStyle:{"width":"60%"},attrs:{"invalid":!_vm.numberListValid,"numberList":_vm.portingOptions.phoneNumbers,"label":"Zu portierende Rufnummern *"},on:{"numberAdded":function($event){return _vm.addNumber($event)},"numberRemoved":function($event){return _vm.removeNumber($event)}}})],1),_c('DisplayContainer',{attrs:{"isSubcomponent":true,"label":"Bisheriger Anbieter"}},[_c('div',{staticClass:"input-row"},[_c('v-autocomplete',{attrs:{"error-messages":_vm.carrierErrorMessage,"item-text":function (item) {
            return ((item.name) + " — (" + (item.routingId) + ")");
          },"items":_vm.fetchedCarrierList,"label":'Bisheriger Anbieter',"loading":_vm.carrierListLoading,"value":_vm.portingOptions.dimariCarrierDto,"clearable":"","persistent-placeholder":"","placeholder":"Carrier auswählen...","return-object":""},on:{"input":function($event){{
            _vm.portingOptions.dimariCarrierDto = $event;
            _vm.emitCurrentState();
          }}}}),_c('div',[_c('div',{staticClass:"label"},[_vm._v(" Vertragspartner ")]),_c('div',[(_vm.portingOptions.dimariCarrierDto !== null)?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.portingOptions.dimariCarrierDto.contractExists ? 'mdi-check-circle' : 'mdi-close-circle')+" ")])]}}],null,false,466822725)},[_c('span',[_vm._v(" "+_vm._s(_vm.portingOptions.dimariCarrierDto.contractExists ? 'Vertragspartner' : 'Kein Vertragspartner')+" ")])])]:[_vm._v(" - ")]],2)])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }