import { HTTP } from '@/main/httpClient.js';
import { hasErrors } from '../ValidationError.js';

function buildAddressDto(customerInformation, installationAddress) {
  const addressDto = {
    salutation: customerInformation.salutation,
    zipCode: installationAddress.zipCode,
    street: installationAddress.street,
    houseNumber: installationAddress.houseNumber,
    houseNumberSupplement: installationAddress.houseNumberSupplement
  };
  if (customerInformation?.salutation === 'COMPANY') {
    addressDto.company = customerInformation.company;
  } else {
    addressDto.firstName = customerInformation.firstName;
    addressDto.lastName = customerInformation.lastName;
  }
  return addressDto;
}

function customerInformationIsValidForBackendCall(
  customerInformation,
  validationErrorsCustomerInformation
) {
  if (!customerInformation || !validationErrorsCustomerInformation) {
    return false;
  } else if (customerInformation?.salutation === 'COMPANY') {
    return (
      customerInformation?.company &&
      !hasErrors(validationErrorsCustomerInformation.company)
    );
  } else {
    return (
      customerInformation?.firstName &&
      customerInformation?.lastName &&
      !hasErrors(validationErrorsCustomerInformation.firstName) &&
      !hasErrors(validationErrorsCustomerInformation.lastName)
    );
  }
}

async function addressIsAvailable(orderType, addressType, addressDto) {
  const queryParameters = {
    orderType: orderType,
    addressType: addressType,
    zipCode: addressDto.zipCode,
    street: addressDto.street,
    houseNumber: addressDto.houseNumber,
    houseNumberSupplement: addressDto.houseNumberSupplement
  };
  if (addressDto.salutation === 'COMPANY') {
    queryParameters.company = addressDto.company;
  } else {
    queryParameters.firstName = addressDto.firstName;
    queryParameters.lastName = addressDto.lastName;
  }
  let backendResult = null;
  try {
    await HTTP.get('/order/addressAvailable', {
      params: queryParameters
    });
  } catch (error) {
    backendResult = error;
  }
  return backendResult;
}

function buildValidationMessagesFromUserValidation(orderType, error) {
  if (!error || error.response.status !== 400) {
    return [];
  } else {
    const collectedErrors = [];
    error.response.data.forEach((validationError) => {
      if (
        validationError.code === 'ORDER_CUSTOMER_ALREADY_EXISTS_AT_LOCATION'
      ) {
        collectedErrors.push(
          `An dieser Adresse gibt es für ${mapOrderTypeToLabel(
            orderType
          )} bereits einen Kunden mit der Kundennummer ${
            validationError.payload.customerId
          }.`
        );
      }
    });
    return collectedErrors;
  }
}

function mapOrderTypeToLabel(orderType) {
  if (orderType === 'SERVICE') {
    return 'den Diensteauftrag';
  } else if (orderType === 'HOUSECONNECTION') {
    return 'Hausanschlussauftrag';
  } else if (orderType === 'HOUSECONNECTION_WITH_SERVICE') {
    return 'den Hausanschluss- und den Diensteauftrag';
  } else {
    return '';
  }
}

export {
  addressIsAvailable,
  buildAddressDto,
  customerInformationIsValidForBackendCall,
  buildValidationMessagesFromUserValidation
};
