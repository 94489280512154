<template>
  <div>
    <div class="input-row margin-bottom-0_5">
      <DataDisplay label="WBCI-Prozess-Typ">
        <div v-if="portingOptions.portPhoneNumbersWithCancellation">
          Rufnummerportierung mit Kündigung (KUE-MRN)
        </div>
        <div v-else>
          Reine Rufnummernportierung (RRNP)
        </div>
      </DataDisplay>
      <DataDisplay label="Alle Rufnummern portieren?">
        <div v-if="portingOptions.portAllPhoneNumbers">
          Alle Rufnummern
        </div>
        <div v-else>
          Nur ausgewählte Rufnummern
        </div>
      </DataDisplay>
      <DataDisplay label="Zu portierende Rufnummern">
        <div v-if="portingOptions.phoneNumbers.length !== 0">
          <div
            v-for="phoneNumber in portingOptions.phoneNumbers"
            :key="phoneNumber"
          >
            <span>{{ phoneNumber }}</span>
          </div>
        </div>
      </DataDisplay>
      <DataDisplay label="Ausgewählter Carrier">
        <div v-if="portingOptions.dimariCarrierDto">
          <template v-if="portingOptions.dimariCarrierDto !== null">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">
                  {{
                    portingOptions.dimariCarrierDto.contractExists
                      ? 'mdi-check-circle'
                      : 'mdi-close-circle'
                  }}
                </v-icon>
              </template>
              <span>
                {{
                  portingOptions.dimariCarrierDto.contractExists
                    ? 'Vertragspartner'
                    : 'Kein Vertragspartner'
                }}
              </span>
            </v-tooltip>
          </template>
          <span>
            {{ portingOptions.dimariCarrierDto.name }}&nbsp;—&nbsp;
            {{ portingOptions.dimariCarrierDto.routingId }}</span>
        </div>
      </DataDisplay>
    </div>
    <ValidationErrorDisplay
      v-if="hasErrors(validationErrorsPortingOptions) === true"
      :validatitationErrors="validationErrorsPortingOptions"
    />
  </div>
</template>

<script>
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import lodash from 'lodash';
import DataDisplay from '@/components/elements/DataDisplay.vue';
import ValidationErrorDisplay from '../ValidationErrorDisplay.vue';
import { hasErrors } from '../ValidationError.js';
export default {
  name: 'PortingOptionsSummary',
  components: {
    DisplayContainer,
    DataDisplay,
    ValidationErrorDisplay
  },
  props: {
    previouslySelectedPortingOptions: {
      type: Object,
      required: false,
      default: null
    },
    validationErrorsPortingOptions: { required: true, type: Object }
  },
  data: () => ({
    portingOptions: null
  }),
  created: function () {
    if (this.previouslySelectedPortingOptions) {
      this.portingOptions = lodash.cloneDeep(
        this.previouslySelectedPortingOptions
      );
    } else {
      this.portingOptions = this.buildDefaultPortingOptions();
    }
  },
  methods: {
    buildDefaultPortingOptions() {
      return {
        portPhoneNumbersWithCancellation: true,
        portAllPhoneNumbers: true,
        dimariCarrierDto: null,
        phoneNumbers: []
      };
    },
    hasErrors(validationErrors) {
      return hasErrors(validationErrors);
    }
  }
};
</script>

<style lang="scss" scoped></style>
