var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.hasErrors(_vm.validationErrors) && _vm.displayError)?_c('ValidationErrorDisplay',{attrs:{"validatitationErrors":_vm.validationErrors}}):_vm._e(),_c('div',{staticClass:"v-checkbox"},[_c('v-checkbox',{attrs:{"hide-details":true,"disabled":_vm.disabled,"dense":"","label":"Grundstückseigentümer zahlt Hausanschlussauftrag"},on:{"change":function($event){return _vm.emitCurrentState()}},model:{value:(_vm.deviatingOwnerInformation.ownerPaysForHouseConnection),callback:function ($$v) {_vm.$set(_vm.deviatingOwnerInformation, "ownerPaysForHouseConnection", $$v)},expression:"deviatingOwnerInformation.ownerPaysForHouseConnection"}})],1),_c('DisplayContainer',{attrs:{"label":"Anschrift","isSubcomponent":true}},[_c('CustomerInformationSelection',{attrs:{"clasS":"margin-top-1","disabled":_vm.disabled,"previouslySelectedCustomerInformation":_vm.deviatingOwnerInformation.customerInformation},on:{"customerInformationChanged":function($event){{
          _vm.deviatingOwnerInformation.customerInformation = $event.value;
          _vm.frontendValidationErrors.customerInformation =
            $event.validationErrors;
          _vm.updateCurrentState();
        }}}}),_c('AddressAutocomplete',{attrs:{"disabled":_vm.disabled,"previouslySelectedAddress":_vm.deviatingOwnerInformation.address},on:{"update":function($event){{
          _vm.deviatingOwnerInformation.address = $event.value;
          _vm.frontendValidationErrors.address = $event.validationErrors;
          _vm.updateCurrentState();
        }}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }