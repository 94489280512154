<template>
  <div>
    <ValidationErrorDisplay
      v-if="hasErrors(validationErrors) && displayError"
      :validatitationErrors="validationErrors"
    />

    <div class="v-checkbox">
      <v-checkbox
        v-model="deviatingOwnerInformation.ownerPaysForHouseConnection"
        :hide-details="true"
        :disabled="disabled"
        dense
        label="Grundstückseigentümer zahlt Hausanschlussauftrag"
        @change="emitCurrentState()"
      ></v-checkbox>
    </div>
    <DisplayContainer label="Anschrift" :isSubcomponent="true">
      <CustomerInformationSelection
        clasS="margin-top-1"
        :disabled="disabled"
        :previouslySelectedCustomerInformation="
          deviatingOwnerInformation.customerInformation
        "
        @customerInformationChanged="
          {
            deviatingOwnerInformation.customerInformation = $event.value;
            frontendValidationErrors.customerInformation =
              $event.validationErrors;
            updateCurrentState();
          }
        "
      />
      <AddressAutocomplete
        :disabled="disabled"
        :previouslySelectedAddress="deviatingOwnerInformation.address"
        @update="
          {
            deviatingOwnerInformation.address = $event.value;
            frontendValidationErrors.address = $event.validationErrors;
            updateCurrentState();
          }
        "
      />
    </DisplayContainer>
  </div>
</template>

<script>
import CustomerInformationSelection from './CustomerInformationSelection.vue';
import AddressAutocomplete from './AddressAutocomplete.vue';

import lodash from 'lodash';

import ValidationErrorDisplay from '../ValidationErrorDisplay.vue';

import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import { hasErrors, mergeValidationErrors } from '../ValidationError.js';

import {
  buildAddressDto,
  customerInformationIsValidForBackendCall,
  addressIsAvailable,
  buildValidationMessagesFromUserValidation
} from './AddressValidationUtil.js';

export default {
  name: 'OwnerSelection',
  components: {
    DisplayContainer,
    ValidationErrorDisplay,
    CustomerInformationSelection,
    AddressAutocomplete
  },
  props: {
    previouslySelectedDeviatingOwnerInformation: {
      type: Object,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    ownerAddressIsHouseConnectionOrderInvoiceAddress: {
      type: Boolean,
      required: false,
      default: null
    },
    displayError: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    deviatingOwnerInformation: null,
    validationErrors: null,
    frontendValidationErrors: null,
    backendValidationErrors: null
  }),
  watch: {
    ownerAddressIsHouseConnectionOrderInvoiceAddress: {
      handler: function (newValue, _) {
        if (newValue === true || newValue === false) {
          this.updateCurrentState();
        }
      }
    }
  },
  created() {
    if (this.$props.previouslySelectedDeviatingOwnerInformation) {
      this.deviatingOwnerInformation = lodash.cloneDeep(
        this.$props.previouslySelectedDeviatingOwnerInformation
      );
    } else {
      this.deviatingOwnerInformation =
        this.buildDefaultDeviatingOwnerSelection();
    }
    this.frontendValidationErrors = this.buildInitialValidationErrors();
    this.updateCurrentState();
  },
  methods: {
    updateCurrentState() {
      this.performBackendValidationIfRequired();
      this.emitCurrentState();
    },
    emitCurrentState() {
      this.validationErrors = mergeValidationErrors(
        this.frontendValidationErrors,
        this.backendValidationErrors
      );
      this.$emit('ownerInformationChanged', {
        value: this.deviatingOwnerInformation,
        validationErrors: this.validationErrors,
        validationOngoing: this.backendCallActive
      });
    },
    performBackendValidationIfRequired: lodash.debounce(
      function () {
        const backendValidationParameters =
          this.deviatingOwnerInformation.customerInformation &&
          this.deviatingOwnerInformation.address
            ? this.buildBackendCallParameters(
              this.ownerAddressIsHouseConnectionOrderInvoiceAddress,
              buildAddressDto(
                this.deviatingOwnerInformation.customerInformation,
                this.deviatingOwnerInformation.address
              )
            )
            : null;
        if (
          backendValidationParameters &&
          // Only do a backend validation if the parameters chanfed
          lodash.isEqual(
            this.backendValidationParameters,
            backendValidationParameters
          ) === false
        ) {
          this.backendValidationErrors = {
            _validationErrors: []
          };
          if (
            this.frontendValidationErrors?.customerInformation &&
            customerInformationIsValidForBackendCall(
              this.deviatingOwnerInformation.customerInformation,
              this.frontendValidationErrors.customerInformation
            ) &&
            !hasErrors(this.frontendValidationErrors.address)
          ) {
            const backendCalls = [];
            this.backendCallActive = true;
            this.emitCurrentState();

            const originalOwnerAddress = lodash.cloneDeep(
              buildAddressDto(
                this.deviatingOwnerInformation.customerInformation,
                this.deviatingOwnerInformation.address
              )
            );

            if (this.ownerAddressIsHouseConnectionOrderInvoiceAddress) {
              backendCalls.push(
                this.checkIfCustomerAlreadyExists(originalOwnerAddress)
              );
            }
            Promise.allSettled(backendCalls)
              .then((results) => {
                if (
                  // Ignore result of call if the call parameters have changed since the start of the call
                  lodash.isEqual(
                    this.backendValidationParameters,
                    backendValidationParameters
                  ) === false
                ) {
                  const displayErrors = results
                    .filter(
                      (promiseResult) => promiseResult.status === 'fulfilled'
                    )
                    .flatMap((promiseResult) => promiseResult.value);
                  this.backendValidationErrors = mergeValidationErrors(
                    this.backendValidationErrors,
                    {
                      _validationErrors: displayErrors
                    }
                  );
                }
              })
              .finally(() => {
                this.backendCallActive = false;
                this.emitCurrentState();
              });
          }
        }
      },
      900,
      { leading: true }
    ),
    buildBackendCallParameters(
      ownerAddressIsHouseConnectionOrderInvoiceAddress,
      addressDto
    ) {
      return {
        ownerAddressIsHouseConnectionOrderInvoiceAddress:
          ownerAddressIsHouseConnectionOrderInvoiceAddress,
        addressDto: addressDto
      };
    },
    checkIfCustomerAlreadyExists: function (originalInstallationAddress) {
      const orderType = 'HOUSECONNECTION';

      return addressIsAvailable(
        orderType,
        'INVOICE',
        originalInstallationAddress
      ).then((result) => {
        return buildValidationMessagesFromUserValidation(orderType, result);
      });
    },
    hasErrors(validationErrors) {
      return hasErrors(validationErrors);
    },
    buildInitialValidationErrors() {
      return {
        _validationErrors: [],
        customerInformation: null,
        address: null
      };
    },
    buildDefaultDeviatingOwnerSelection() {
      return {
        ownerPaysForHouseConnection: false,
        customerInformation: null,
        address: null
      };
    }
  }
};
</script>

<style scoped>
/* div::v-deep .v-checkbox {
  margin-top: 0px;
  padding-top: 0px;} */

.v-checkbox ::v-deep .v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
</style>
