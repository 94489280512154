var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DisplayContainer',{attrs:{"label":" Einzelverbindungsnachweise (EVN) ","isSubcomponent":true}},[_c('div',{staticClass:"input-row"},[_c('DataDisplay',{attrs:{"label":"Art des EVN"}},[_vm._v(" "+_vm._s(_vm.itemizedBillTypes[_vm.serviceOrderOptions.itemizedBillsGenerationType])+" ")])],1)]),_c('DisplayContainer',{attrs:{"label":"Telefonbucheintrag (kostenlos) ","isSubcomponent":true}},[(_vm.hasErrors(_vm.validationErrors.phoneBookOptions) === true)?_c('ValidationErrorDisplay',{attrs:{"validatitationErrors":_vm.flattenAllErrors(_vm.validationErrors.phoneBookOptions)}}):_vm._e(),_c('div',{staticClass:"input-row"},[_c('DataDisplay',{attrs:{"label":"Art des Telefonbucheintrags"}},[_c('div',[_vm._v(" "+_vm._s(_vm.phoneBookRecordTypes[ _vm.serviceOrderOptions.phoneBookOptions.phoneBookRecordType ])+" ")])]),(
          _vm.serviceOrderOptions.phoneBookOptions.phoneBookRecordType !==
            'NO_ENTRY'
        )?_c('DataDisplay',{attrs:{"label":"Herausgabe von Teilnehmerdaten für die Rückwärtssuche wird zugestimmt"}},[_c('div',[_vm._v(" "+_vm._s(_vm.serviceOrderOptions.phoneBookOptions.allowPhoneNumberSearch ? 'Ja' : 'Nein')+" ")])]):_vm._e(),_c('NameDisplay',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.serviceOrderOptions.phoneBookOptions.phoneBookRecordType !==
            'NO_ENTRY'
        ),expression:"\n          serviceOrderOptions.phoneBookOptions.phoneBookRecordType !==\n            'NO_ENTRY'\n        "}],attrs:{"addressDto":_vm.serviceOrderOptions.phoneBookOptions.phoneBookEntryAddress
            .customerInformation}}),_c('AddressDisplay',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.serviceOrderOptions.phoneBookOptions.phoneBookRecordType !==
            'NO_ENTRY'
        ),expression:"\n          serviceOrderOptions.phoneBookOptions.phoneBookRecordType !==\n            'NO_ENTRY'\n        "}],attrs:{"addressDto":_vm.serviceOrderOptions.phoneBookOptions.phoneBookEntryAddress.address}})],1)],1),_c('DisplayContainer',{attrs:{"label":"Rechnungsversand","isSubcomponent":true}},[_c('div',{staticClass:"input-row"},[_c('DataDisplay',{attrs:{"label":"Rechnungsversand"}},[_c('div',[_vm._v(" "+_vm._s(_vm.invoiceShippingMethods[_vm.serviceOrderOptions.invoiceShippingMethod])+" ")])])],1)]),_c('DisplayContainer',{attrs:{"label":"Löschung der Verkehrsdaten von Telefonverbindungen ","isSubcomponent":true}},[_c('div',{staticClass:"input-row"},[_c('DataDisplay',{attrs:{"label":"Löschung der Verkehrsdaten von Telefonverbindungen"}},[_c('div',[_vm._v(" "+_vm._s(_vm.storingPeriodsOfConnectionInformation[ _vm.serviceOrderOptions.storingPeriodOfConnectionInformation ])+" ")])])],1)]),_c('DisplayContainer',{attrs:{"label":"Information zu Produkten, Dienstleistungen und Unternehmensgeschehen ","isSubcomponent":true}},[_c('div',{staticClass:"input-row"},[_c('DataDisplay',{attrs:{"label":"Beratungseinwilligung"}},[_c('div',[_vm._v(" "+_vm._s(_vm.serviceOrderOptions.allowAdvertisement ? 'Wurde erteilt' : 'Wurde nicht erteilt')+" ")])])],1)]),_c('DisplayContainer',{attrs:{"label":"Neurufnummernvergabe","selectionConfiguration":{
      label: 'Neurufnummernvergabe durchführen',
      isSelected:
        _vm.serviceOrderOptions.newPhoneNumberDetails.provideNewPhoneNumbers,
      isFoldable: true,
      disabled: true
    },"isSubcomponent":true}},[_c('div',{staticClass:"input-row"},[_c('DataDisplay',{attrs:{"label":"Anzahl neuer Rufnummern"}},[_vm._v(" "+_vm._s(_vm.serviceOrderOptions.newPhoneNumberDetails.numberOfNewPhoneNumbers)+" ")]),_c('DateDisplay',{attrs:{"label":"Anschaltdatum","date":_vm.serviceOrderOptions.newPhoneNumberDetails.requestedProvidingDate}})],1)]),_c('DisplayContainer',{attrs:{"selectionConfiguration":{
      label: 'Übernahme eines bestehenden Anschlusses inkl. Rufnummer',
      isSelected: _vm.serviceOrderOptions.performPhoneNumberPorting,
      isFoldable: true,
      disabled: true
    },"label":"Portierung Durchführen","isSubcomponent":true}},[_c('PortingOptionsSummary',{attrs:{"previouslySelectedPortingOptions":_vm.serviceOrderOptions.portingDetails,"validationErrorsPortingOptions":_vm.validationErrors.portingDetails}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }