<template>
  <div>
    <ValidationErrorDisplay
      v-if="hasErrors(validationErrors) && displayError"
      :validatitationErrors="validationErrors"
    />
    <ValidationErrorDisplay
      v-if="hasErrors(backendValidationErrors) && !displayError"
      :validatitationErrors="backendValidationErrors"
    />
    <CustomerInformationSelection
      :disabled="disabled"
      :previouslySelectedCustomerInformation="
        invoiceAddress.customerInformation
      "
      @customerInformationChanged="
        {
          invoiceAddress.customerInformation = $event.value;
          frontendValidationErrors.customerInformation =
            $event.validationErrors;
          updateCurrentState();
        }
      "
    />
    <AddressAutocomplete
      :previouslySelectedAddress="invoiceAddress.address"
      :disabled="disabled"
      @update="
        {
          invoiceAddress.address = $event.value;
          frontendValidationErrors.address = $event.validationErrors;
          updateCurrentState();
        }
      "
    />
  </div>
</template>

<script>
import AddressAutocomplete from './AddressAutocomplete.vue';
import ValidationErrorDisplay from '../ValidationErrorDisplay.vue';

import lodash from 'lodash';
import { hasErrors, mergeValidationErrors } from '../ValidationError.js';
import {
  buildAddressDto,
  customerInformationIsValidForBackendCall,
  addressIsAvailable,
  buildValidationMessagesFromUserValidation
} from './AddressValidationUtil.js';
import CustomerInformationSelection from './CustomerInformationSelection.vue';

export default {
  name: 'InvoiceAddressSelection',
  components: {
    AddressAutocomplete,
    ValidationErrorDisplay,
    CustomerInformationSelection
  },
  props: {
    previouslySelectedInvoiceAddress: {
      type: Object,
      required: false,
      default: null
    },
    invoiceAddressIsServiceOrderInvoiceAddress: {
      type: Boolean,
      required: false,
      default: null
    },
    invoiceAddressIsHouseConnectionOrderInvoiceAddress: {
      type: Boolean,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    displayError: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    invoiceAddress: null,
    validationErrors: null,
    frontendValidationErrors: null,
    backendValidationErrors: null,
    missingFieldErrors: [],
    missingFieldErrorsAddress: [],
    backendValidationParameters: null
  }),
  watch: {
    invoiceAddressIsServiceOrderInvoiceAddress: {
      handler: function (newValue, _) {
        if (newValue === true || newValue === false) {
          this.updateCurrentState();
        }
      }
    },
    invoiceAddressIsHouseConnectionOrderInvoiceAddress: {
      handler: function (newValue, _) {
        if (newValue === true || newValue === false) {
          this.updateCurrentState();
        }
      }
    }
  },
  created() {
    if (this.$props.previouslySelectedInvoiceAddress) {
      this.invoiceAddress = lodash.cloneDeep(
        this.$props.previouslySelectedInvoiceAddress
      );
    } else {
      this.invoiceAddress = this.buildDefaultInvoiceAddress();
    }
    this.frontendValidationErrors = this.buildInitialValidationErrors(
      this.invoiceAddress
    );
    this.updateCurrentState();
  },
  methods: {
    updateCurrentState() {
      this.performBackendValidationIfRequired();
      this.emitCurrentState();
    },
    buildDefaultInvoiceAddress() {
      return {
        customerInformation: null,
        address: null
      };
    },
    performBackendValidationIfRequired: lodash.debounce(
      function () {
        const backendValidationParameters = this.buildBackendCallParameters(
          this.invoiceAddressIsServiceOrderInvoiceAddress,
          this.invoiceAddressIsHouseConnectionOrderInvoiceAddress,
          this.invoiceAddress
        );
        if (
          // Only do a backend validation if the parameters chanfed
          lodash.isEqual(
            this.backendValidationParameters,
            backendValidationParameters
          ) === false
        ) {
          this.backendValidationErrors = {
            _validationErrors: []
          };
          if (
            customerInformationIsValidForBackendCall(
              this.invoiceAddress.customerInformation,
              this.invoiceAddress.address
            ) &&
            !hasErrors(this.frontendValidationErrors.address)
          ) {
            const originalInvoiceAddress = lodash.cloneDeep(
              buildAddressDto(
                this.invoiceAddress.customerInformation,
                this.invoiceAddress.address
              )
            );
            const backendCalls = [];
            this.backendCallActive = true;
            this.emitCurrentState();

            if (
              this.invoiceAddressIsServiceOrderInvoiceAddress ||
              this.invoiceAddressIsHouseConnectionOrderInvoiceAddress
            ) {
              backendCalls.push(
                this.checkIfCustomerAlreadyExists(originalInvoiceAddress)
              );
            }
            Promise.allSettled(backendCalls)
              .then((results) => {
                if (
                  // Ignore result of call if the call parameters have changed since the start of the call
                  lodash.isEqual(
                    this.backendValidationParameters,
                    backendValidationParameters
                  ) === false
                ) {
                  const displayErrors = results
                    .filter(
                      (promiseResult) => promiseResult.status === 'fulfilled'
                    )
                    .flatMap((promiseResult) => promiseResult.value);
                  this.backendValidationErrors = mergeValidationErrors(
                    this.backendValidationErrors,
                    {
                      _validationErrors: displayErrors
                    }
                  );
                }
              })
              .finally(() => {
                this.backendCallActive = false;
                this.emitCurrentState();
              });
          }
        }
      },
      900,
      { leading: true }
    ),
    buildBackendCallParameters(
      invoiceAddressIsServiceOrderInvoiceAddress,
      invoiceAddressIsHouseConnectionOrderInvoiceAddress,
      invoiceAddress
    ) {
      return {
        invoiceAddressIsServiceOrderInvoiceAddress:
          invoiceAddressIsServiceOrderInvoiceAddress,
        invoiceAddressIsHouseConnectionOrderInvoiceAddress:
          invoiceAddressIsHouseConnectionOrderInvoiceAddress,
        invoiceAddress: invoiceAddress
      };
    },
    hasErrors(validationErrors) {
      return hasErrors(validationErrors);
    },
    checkIfCustomerAlreadyExists: function (originalInvoiceAddress) {
      let orderType = null;
      if (
        this.invoiceAddressIsServiceOrderInvoiceAddress &&
        this.invoiceAddressIsHouseConnectionOrderInvoiceAddress
      ) {
        orderType = 'HOUSECONNECTION_WITH_SERVICE';
      } else if (this.invoiceAddressIsServiceOrderInvoiceAddress) {
        orderType = 'SERVICE';
      } else {
        orderType = 'HOUSECONNECTION';
      }
      return addressIsAvailable(
        orderType,
        'INVOICE',
        originalInvoiceAddress
      ).then((result) => {
        return buildValidationMessagesFromUserValidation(orderType, result);
      });
    },
    emitCurrentState() {
      this.validationErrors = mergeValidationErrors(
        this.frontendValidationErrors,
        this.backendValidationErrors
      );
      this.$emit('invoiceAddressChanged', {
        value: this.invoiceAddress,
        validationErrors: this.validationErrors,
        validationOngoing: this.backendCallActive
      });
    },
    buildInitialValidationErrors() {
      return {
        _validationErrors: [],
        customerInformation: null,
        address: null
      };
    }
  }
};
</script>

<style lang="scss" scoped></style>
