var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.hasErrors(_vm.validationErrors) && _vm.displayError)?_c('ValidationErrorDisplay',{attrs:{"validatitationErrors":_vm.validationErrors}}):_vm._e(),_c('div',{staticClass:"input-row margin-bottom-1"},[_c('v-text-field',{attrs:{"label":"Kontoinhaber *","error-messages":_vm.validationErrors.owner._validationErrors,"dense":"","disabled":_vm.disabled,"persistent-placeholder":""},on:{"input":function($event){{
          _vm.validationErrors.owner._validationErrors = _vm.validateOwner($event);
          _vm.currentStateChanged();
        }}},model:{value:(_vm.sepaMandate.owner),callback:function ($$v) {_vm.$set(_vm.sepaMandate, "owner", $$v)},expression:"sepaMandate.owner"}}),_c('v-text-field',{attrs:{"label":"IBAN *","error-messages":_vm.validationErrors.iban._validationErrors,"dense":"","disabled":_vm.disabled,"persistent-placeholder":""},on:{"change":function($event){{
          _vm.validationErrors.iban._validationErrors = _vm.validateIban($event);
          _vm.currentStateChanged();
          _vm.getBic($event);
        }}},model:{value:(_vm.sepaMandate.iban),callback:function ($$v) {_vm.$set(_vm.sepaMandate, "iban", $$v)},expression:"sepaMandate.iban"}})],1),_c('div',{staticClass:"input-row"},[_c('v-text-field',{attrs:{"label":"Kreditinstitut","error-messages":_vm.validationErrors.bank._validationErrors,"dense":"","disabled":_vm.disabled,"persistent-placeholder":""},on:{"input":function($event){{
          _vm.validationErrors.bank._validationErrors = _vm.validateBank($event);
          _vm.currentStateChanged();
        }}},model:{value:(_vm.sepaMandate.bank),callback:function ($$v) {_vm.$set(_vm.sepaMandate, "bank", $$v)},expression:"sepaMandate.bank"}}),_c('v-text-field',{attrs:{"label":"BIC","error-messages":_vm.validationErrors.bic._validationErrors,"dense":"","disabled":_vm.disabled,"persistent-placeholder":""},on:{"input":function($event){{
          _vm.validationErrors.bic._validationErrors = _vm.validateBic($event);
          _vm.currentStateChanged();
        }}},model:{value:(_vm.sepaMandate.bic),callback:function ($$v) {_vm.$set(_vm.sepaMandate, "bic", $$v)},expression:"sepaMandate.bic"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }